import React from "react";

import { Document, Text, StyleSheet } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";

const Heading = styled.View`
  font-size: 10px;
  display: flex;
  padding: 5px;
`;

const BlockTwo = styled.View`
  font-size: 8px;
  padding: 5px;

  border-top: 1px solid #000;
`;

const BlockThree = styled.View`
  font-size: 12px;
  padding: 5px;
  border: 1px solid #000;
  border-right: 0;
  border-left: 0;
`;

const BlockFour = styled.View`
  font-size: 10px;
  padding: 5px;
`;

const BoxOrigin = styled.Text`
  font-weight: bold;
  margin-left: 10px;
  font-size: 9px;
`;

const Container = styled.Page`
  border: 1px solid #000;
  height: 99%;
`;

const Border = styled.View`
  border: 1px solid #000;
  margin: 10px;
  margin-bottom: 0px;
  height: 96%;
`;

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
  },

  section: {
    margin: 10,
    padding: 10,
    flexGrow: 3,
  },
  bold: {
    fontWeight: "bold",
  },
  dflex: {
    flexDirection: "row",
  },
  borderX: {
    paddingHorizontal: "5px",
    marginHorizontal: "5px",
    borderLeft: "1px",
    marginVertical: "0px",
    borderRight: "1px",
  },
  MarginY: {
    marginVertical: "10px;",
  },
  borderR: {
    borderRight: "1px",
  },
  destinyAddress: {
    fontSize: "10px",
    marginTop: "5px",
  },
});

export default function TagPdf({ order }) {
  const destiny = order.shipping_address;

  const origin = order.billing_address;

  return (
    <>
      <Document>
        {order.items.map((item, index) => (
          <Container key={index} size="A6">
            <Border>
              <Heading style={styles.dflex}>
                {/* <Text>Logo</Text> */}
                <div>
                  <BoxOrigin style={styles.bold}>ORIGEM</BoxOrigin>
                  <BoxOrigin>{origin.name}</BoxOrigin>
                  <BoxOrigin>
                    {origin.address}, {origin.number}
                  </BoxOrigin>
                  <BoxOrigin>{origin.neighborhood}</BoxOrigin>
                  <BoxOrigin>
                    {origin.city} - {origin.state}
                  </BoxOrigin>
                  <BoxOrigin>
                    {origin.postcode} - {origin.country}
                  </BoxOrigin>
                  <BoxOrigin>
                    CNPJ: {origin.vat_number} Telefone: {origin.phone}
                  </BoxOrigin>
                </div>
              </Heading>
              <BlockTwo style={styles.dflex}>
                <Text>Postagem: 10/03/2017 00:00:00</Text>
                <Text style={styles.borderX}>Peso: </Text>
                <Text>Volumes: {item.qty}</Text>
              </BlockTwo>
              <BlockThree>
                <Text>DESTINO:</Text>
                <div
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <Text>
                    {order.customer_first_name} {order.customer_last_name}
                  </Text>

                  <div style={styles.destinyAddress}>
                    <Text>
                      {destiny.address}, {destiny.number}
                    </Text>

                    <Text>
                      {destiny.city} - {destiny.state}
                    </Text>
                    <Text>{destiny.country}</Text>
                  </div>
                  <Text style={styles.MarginY}>CEP: {destiny.postcode}</Text>
                  <Text>
                    A/C: {order.customer_first_name} {order.customer_last_name}
                  </Text>
                </div>
              </BlockThree>
              <BlockFour style={styles.MarginY}>
                <Text>NOTA FISCAL</Text>
                <Text>
                  {order.note_number}
                  {/* 1638- Autorizada - Emitida em 09/03/2017 11:42 */}
                </Text>
              </BlockFour>
            </Border>
          </Container>
        ))}
      </Document>
    </>
  );
}
