import React, { useEffect, useState } from "react";
import i18n from "../../i18n";
import api from "../../Services/api";

import currencyFormat from "../../Services/Utils/currencyFormat";

import { BlobProvider } from "@react-pdf/renderer";
import TagPdf from "../TagPdf";

export default function DetailsCompleted(props) {
  const { order, ordersReload} = props;

  const [zippinTagUrl, setzippinTagUrl] = useState();
  const [loading, setLoading] = useState();
  const [image, setImage] = useState('empty');
  const formData = new FormData();



  const sendStatusCompleted = async (props) => {

    if(order.shipping_method == "withdraw-in-store" && image != 'empty'){
      formData.append("image", image, image.name)
      api
          .post("/order/" + props.order.id + "/delivery", formData)
          .then(props.changeOrderState("completed"),ordersReload())
          .catch((error) => console.log(error))

    }  else {
      api
          .post("/order/" + props.order.id + "/delivery", [])
          .then(props.changeOrderState("completed"),ordersReload())
          .catch((error) => console.log(error))
    }

  };



  const onFileUpload = async (e) => {
    setImage(e.target.files[0])
  };

  useEffect(() => {
    if (
      order.shipping_method === "zippin" &&
      props.order.status === "completed"
    ) {
      (async function () {
        setLoading(true);

        const data = {
          order_id: order.id,
        };

        try {
          const { data: response } = await api.post("/zippin/tag", data);

          setzippinTagUrl(response.url);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [order.id, order.shipping_method, props.order.status]);

  return (
    <div className=" mt-3">
      {props.order.status !== "pending_payment" && (
        <div className="col-lg-12">
          <div className="row justify-content-center">
            {props.order.status === "canceled" ? (
              <div className="w-100 text-center my-3">
                {" "}
                <span className="bg-danger py-2 text-white font-weight-bold px-4 h3">
                  {i18n.t("orders:modalOrder.canceled")}
                </span>{" "}
              </div>
            ) : (
              <div className="text-center">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        {i18n.t("orders:modalOrder.noteNumber")}
                      </th>
                      <th scope="col">
                        {i18n.t("orders:modalOrder.noteKeyNumber")}
                      </th>
                      <th scope="col">
                        {" "}
                        { order.shipping_method == 'withdraw-in-store' ? "" : i18n.t("orders:modalOrder.trackingCode")}
                      </th>
                      <th scope="col">
                        {i18n.t("orders:modalOrder.shippingCompany")}
                      </th>

                      <th>{order.shipping_method == 'withdraw-in-store' ? "" : i18n.t("orders:modalOrder.shippingTimeDays")}</th>
                      <th scope="col">
                        {" "}
                        {i18n.t("orders:modalOrder.shippingValue")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>{props.order.note_number}</td>
                      <td> {props.order.note_key} </td>
                      <td>{props.order.code_tracking}</td>
                      <td>{props.order.shipping_company}</td>

                      <td>
                        {props.order.delivery_time < 1
                          ? ""
                          : props.order.delivery_time}
                      </td>
                      <td>{currencyFormat(props.order.shipping_amount)}</td>
                    </tr>
                  </tbody>
                </table>

                {order.status == 'ready_to_delivery' ? (
                    <>
                      <div style={{'border':'solid 1px #fff'}}>
                    <label>Anexar Comprovante de Entrega</label><br/>
                    <input type="file"  onChange={onFileUpload}/><br/><br/>
                      </div>
                    </>
                ) : ("")}



                {props.order.status === "completed" || props.order.status === "delivered" ? (
                  <div className="text-white mt-3 mb-5 w-100 text-center ">
                    <span className="h3 bg-success h3 bg-success py-2 px-4">
                      {i18n.t("orders:modalOrder.concluded")}
                    </span>

                    <div className="my-5">
                      {loading ? (
                        <i className="fa fa-2x fa-spinner fa-pulse"></i>
                      ) : (
                        zippinTagUrl && (
                          <a
                            href={zippinTagUrl}
                            className="h5 px-3 bg-info"
                            target="_new"
                          >
                            Etiqueta
                          </a>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                    <div>

                    </div>,
                  <button
                    type="button"
                    className="btn btn-success px-5"
                    onClick={() => sendStatusCompleted(props)}
                    style={{'margin':'6px'}}
                  >
                    <h6>{i18n.t("orders:modalOrder.confirmSendOrder")}</h6>


                  </button>

                )}

              </div>
            )}

            { props.order.status === "completed" || props.order.status === "delivered" || props.order.status === "canceled" ? "" : (<strong>Clique em <span style={{'color':'#246e3d'}}> CONFIRMAR ENTREGA</span> se tem certeza que o produto foi entregue ao cliente.</strong>)}
          </div>
        </div>
      )}
    </div>
  );
}
