import React from "react";
import "@mdi/font/css/materialdesignicons.min.css";
import { Link, NavLink } from "react-router-dom";

import { logout } from "../../../../Services/auth";
import LogoAlluzy from "../../../../assets/images/alluzy.png";
import LogoCazco from "../../../../assets/images/logo_cazco.svg";

import "./styles.css";

import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../Contexts/LocaleContext";
import { useUserData } from "../../../../Contexts/hooks/useUserData";
import { Dropdown } from "react-bootstrap";

export default function Navbar() {
  const { locale } = useLocale();

  const { userData, loading: loadingDataUser } = useUserData();

  const { companyId, name, logo } = userData;

  const { t } = useTranslation("navbar");

  return (
    <>
      {/* Navigation Bar */}
      <header id="topnav">
        <div className="topbar-main">
          <div className="container-fluid d-flex align-items-center justify-content-between">
            {/* Logo container */}
            <div className="logo">
              <Link to={"/dashboard"} className="logo">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/original/theme/assets/images/SVG/logo_painel_lojistaz_small.svg"
                  }
                  alt=""
                  className="logo-small"
                ></img>
                {/* <img src="assets/images/SVG/logo_painel_lojistaz_small.svg" alt="" className="logo-small"></img> */}

                <img
                  src={
                    process.env.REACT_APP_URL +
                    "/original/theme/assets/images/SVG/logo_painel_lojistaz.svg"
                  }
                  alt=""
                  className="logo-large"
                ></img>

                {/* <img src="assets/images/SVG/logo_painel_lojistaz.svg" alt="" className="logo-large"></img> */}
              </Link>
            </div>
            {/* End Logo container */}

            <div className="welcome">
              {loadingDataUser ? (
                <i className="fa fa-2x fa-spinner fa-pulse"></i>
              ) : (
                <h4 className="page-title">
                  {t("welcome")}, {name}{" "}
                </h4>
              )}
            </div>

            <div className="menu-extras topbar-custom">
              <ul className="float-right list-unstyled mb-0 ">
                <li className="dropdown notification-list">
                  <a
                    className="nav-link dropdown-toggle arrow-none waves-effect"
                    data-toggle="dropdown"
                    href="/#"
                    role="button"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    {/* <i className="ti-bell noti-icon"></i> */}
                    {/* <span className="badge badge-pill badge-danger noti-icon-badge">3</span> */}
                  </a>
                </li>
                <li className="dropdown notification-list">
                  <div className="dropdown notification-list nav-pro-img">
                    <div className="arrow-none waves-effect nav-user d-flex align-items-center">
                      <Link to={"/"} className="mr-4" onClick={logout}>
                        {t("logout")}
                      </Link>

                      {logo && (
                        <Link to={"configloja"}>
                          <img
                            src={logo}
                            alt="user"
                            className="rounded-circle"
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                </li>
                <li className="menu-item">
                  {/* Mobile menu toggle */}
                  <a
                    href="/#"
                    className="navbar-toggle nav-link"
                    id="mobileToggle"
                  >
                    <div className="lines">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                  {/* End mobile menu toggle */}
                </li>
              </ul>
            </div>
            {/* end menu-extras */}
            {/* 
            <div className="clearfix"></div> */}
          </div>{" "}
          {/* end container */}
        </div>
        {/* end topbar-main */}
        <div className="container-fluid">
          {/* Page-Title */}
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box"></div>
            </div>
          </div>
        </div>

        <div className="navbar-custom">
          <div className="container-fluid">
            <div
              id="navigation"
              className="d-flex justify-content-between align-items-center"
            >
              <ul className="navigation-menu d-flex align-items-center">
                <li className="has-submenu">
                  <NavLink to="/dashboard" activeStyle={{ color: "#f27427" }}>
                    <i className="ti-dashboard"></i>
                    <span>{t("dashboard")}</span>
                  </NavLink>
                </li>
                <li className="has-submenu">
                  <NavLink to="/pedidos" activeStyle={{ color: "#f27427" }}>
                    <i className="ti-receipt"></i>
                    <span>{t("orders")}</span>
                  </NavLink>
                </li>
                <li className="has-submenu">
                  <NavLink to="/catalogo" activeStyle={{ color: "#f27427" }}>
                    <i className="ti-agenda"></i>
                    <span>{t("catalog")}</span>
                  </NavLink>
                </li>

                {companyId === 3 && (
                  <li className="has-submenu special-tools text-center">
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        <i className="ti-paint-roller"></i>
                        Ferramentas Especiais
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href={"https://tintometrico.lojacoral.com/tintingcoral/authorize/" + localStorage.getItem('@seller-panel-Token')}  target= "_new">
                          Tintométrico 
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                )}

                <li className="has-submenu">
                  <NavLink to="/configloja" activeStyle={{ color: "#f27427" }}>
                    <i className="ti-dashboard"></i>
                    <span>{t("settings")}</span>
                  </NavLink>
                </li>
                <li className="has-submenu">
                  <a
                    href="https://cazcodigital.atlassian.net/servicedesk/customer/portal/2 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-help"></i>
                    {t("help")}
                  </a>
                </li>
              </ul>
              <div className="d-flex align-items-center mr-5">
                <h4 className="m-0 pr-3"> MARKETPLACE</h4>
                <div>
                  <img src={locale === "es" ? LogoAlluzy : LogoCazco} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
