import React, { useState } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import "../../../Pages/Auth/Includes/Loader/Loader.css";
import history from "../../../history";
import "@mdi/font/css/materialdesignicons.min.css";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../Contexts/hooks/useUserData";

export default function LoginForm() {
  const [loader, setLoader] = useState();

  const { t } = useTranslation(["home", "form"]);

  const { loginService } = useUserData();

  const handleSignIn = async (fields) => {
    setLoader(true);

    await loginService(fields, history);

    setLoader(false);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("form:errors.email"))
          .required(t("form:errors.email")),
        password: Yup.string()
          .min(6, t("form:errors.minPassword"))
          .required(t("form:errors.password")),
      })}
      onSubmit={(fields) => {
        handleSignIn(fields);
      }}
    >
      {({ errors, status, touched }) => (
        <Form>
          <div className="pagesAuth form-group">
            <label htmlFor="email" className="text-dark">
              Email
            </label>
            <Field
              name="email"
              type="text"
              className={
                "pagesAuth form-control1" +
                (errors.email && touched.email ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback"
              placeholder="Digite um email"
            />
          </div>
          <div className="pagesAuth form-row">
            <div className="form-group col">
              <label htmlFor="password" className="text-dark">
                {t("password")}
              </label>
              <Field
                name="password"
                type="password"
                className={
                  "pagesAuth form-control1" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
                placeholder="Digite uma senha"
              />
            </div>
          </div>
          <div className="form-group mt-4 text-right">
            {loader ? (
              <i className="fa fa-2x fa-spinner fa-pulse"></i>
            ) : (
              <button type="submit" className="btn btn-primary">
                {t("buttonLogin")}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
