import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import api from "../../Services/api";
import popUp from "../../Services/Utils/swalPopup";
import Cookies from "js-cookie";
import { saveToken } from "../../Services/auth";
const UserDataContext = createContext({});

export default function UserDataProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const cookieUserData = Cookies.get("userData");
  const [userData, setUserData] = useState(
    cookieUserData ? JSON.parse(cookieUserData) : []
  );

  async function getCompanyId() {
    try {
      setLoading(true);
      const { data: response } = await api.get("/companies");

      console.log("====================================");
      console.log(response.data[0].company_id);
      console.log("====================================");

      return response.data[0].company_id;
    } catch (err) {
      popUp("error", "Oops...", err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function getCompanyName() {
    try {
      setLoading(true);
      const { data: response } = await api.get("/companies");

      return response.data[0].company_name;
    } catch (err) {
      popUp("error", "Oops...", err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function getUserLogo() {
    try {
      setLoading(true);
      const { data: response } = await api.get("/image");

      return response.data;
    } catch (err) {
      popUp("error", "Oops...", err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  const getDataUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get("/get");

      const { data } = response;
      const { name, email } = data;

      data.logo = await getUserLogo();

      data.companyId = await getCompanyId();

      data.companyName = await getCompanyName();

      /**Compass integration */
      if (window.Conpass) {
        window.Conpass.init({
          name: name,
          email: email,
        });
      }

      const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);

      Cookies.set("userData", data, {
        expires: inOneHour,
      });

      setUserData(response.data);
    } catch (err) {
      popUp("error", "Oops...", err.response.data.message);
    } finally {
      setLoading(false);
      return false;
    }
  }, []);

  const loginService = useCallback(
    async (fields, history) => {
      try {
        const response = await api.post("/login", fields);

        saveToken(response.data.token);

        getDataUser();

        history.push("/dashboard");
      } finally {
        return false;
      }
    },
    [getDataUser]
  );

  const value = useMemo(
    () => ({
      userData,
      loading,
      getDataUser,
      loginService,
    }),
    [userData, loading, getDataUser, loginService]
  );

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

export const useUserData = () => useContext(UserDataContext);
