import React from "react";
import Routes from "./Components/Routes";
import "./i18n";
import { Router } from "react-router-dom";
import history from "./history";
import LocaleProvider from "./Contexts/LocaleContext";
import UserDataProvider from "./Contexts/hooks/useUserData";

require("dotenv").config();

const App = () => (
  <Router history={history}>
    <UserDataProvider>
      <LocaleProvider>
        <Routes />
      </LocaleProvider>
    </UserDataProvider>
  </Router>
);

export default App;
