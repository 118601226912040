import React, { useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import api from "../../../../Services/api";
import popUp from "../../../../Services/Utils/swalPopup";
import currencyFormat from "../../../../Services/Utils/currencyFormat";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";

import DetailsTracking from "../../../../Components/DetailsTracking";
import DetailsCompleted from "../../../../Components/DetailsCompleted";

const progress = (status) => {
  /* eslint-disable */
  if (status == "pending") return 25;
  if (status == "processing") return 50;
  if (status == "pending_payment") return 2;
  if (status == "completed") return 100;
  if (status == "canceled") return 100;
  if (status == "awaiting_confirmation") return 25;
  if (status == "unavailable") return 100;
  if (status == "billed") return 75;
  if (status == "ready_to_delivery") return 85;
  if (status == "shipped") return 90;
  if (status == "delivered") return 100;
};

const VaryingDetails = (props) => {
  const { order } = props;

  if (order.delivery_time != null) {
    return <DetailsCompleted {...props} />;
  }

  switch (order.status) {
    case "processing":
      return <DetailsProcessing {...props} />;

    case "billed":
      return <DetailsTracking {...props} />;

    case "pending":
      return <DetailsPending {...props} />;

    case "awaiting_confirmation":
      return <DetailsPending {...props} />;

    case "pending_payment":
      return <DetailsCompleted {...props} />;

    case "delivered":
      return <DetailsCompleted {...props} />;

    case "unavailable":
      return <DetailsUnavailable {...props} />;

    default:
      return <DetailsCompleted {...props} />;
  }
};

const DetailsPending = (props) => {
  return (
      <div className="row mt-3">
        <div className="col-lg-12 col-sm d-flex justify-content-center" style={{'margin':'10px'}}>
          <button
              type="button"
              className="btn btn-success px-5"
              onClick={() => updateStatus(props, "processing")}
          >
            {i18n.t("orders:modalOrder.confirmOrder")}
          </button>

        </div>
        <div className="col-lg-12 col-sm d-flex justify-content-center" style={{'margin':'10px'}}>
          <button
              type="button"
              className="btn btn-danger px-5"
              onClick={() => updateStatus(props, "unavailable")}
          >
            {i18n.t("orders:modalOrder.unavailableOrder")}

          </button>
        </div>
        <div className="col-lg-12 col-sm d-flex justify-content-center" style={{'margin':'6px'}}>
          <strong>Caso o pedido seja colocado como <span style={{'color':'#ff0000'}}>INDISPONÍVEL</span>, a comissão será cobarada normalmente</strong>
        </div>
      </div>
  );
};

const DetailsUnavailable = (props) => {
  return (
      <div className="row mt-3">
        <div className="col-lg-12 col-sm d-flex justify-content-center">
          <button
              type="button"
              className="btn btn-danger px-5"
          >
            {i18n.t("orders:modalOrder.unavailableOrder")}
          </button>
        </div>
      </div>
  );
};

function DetailsProcessing(props) {
  const [loader, setLoader] = useState(false);

  const { changeOrderState } = props;

  const sendNoteInfo = async (fields, props) => {
    setLoader(true);

    changeOrderState("billed");

    try {
      await api.put("order/" + props.order.id + "/note", fields);

      props.loadDataModal(props.order.id);

      props.ordersReload();

    } catch (err) {

      popUp("error", "Oh No...", err.message);
    } finally {
      setLoader(false);
    }
  };

  const validations = Yup.object().shape({
    note_key: Yup.number()
        .typeError("Apenas números")
        .integer("Apenas números inteiros")
        .required("Chave da nota é obrigatória"),
    note_number: Yup.number()
        .typeError("Apenas números")
        .required("Número da nota é obrigatório!"),
  });

  return (
      <Formik
          initialValues={{
            note_key: "",
            note_number: "",
          }}
          validationSchema={validations}
          onSubmit={(fields) => {
            sendNoteInfo(fields, props);
          }}
      >
        {({ errors, status, touched }) => (
            <Form>
              <div className="row mt-3">
                <div className="form-group col">
                  <label htmlFor="text">
                    {i18n.t("orders:modalOrder.insertNoteCode")}
                  </label>
                  <Field
                      type="number"
                      name="note_number"
                      className={
                          "pagesAuth no-arrows form-control" +
                          (errors.note_key && touched.note_key ? " is-invalid" : "")
                      }
                  />

                  <ErrorMessage
                      name="note_key"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="text">
                    {i18n.t("orders:modalOrder.insetKeyCode")}{" "}
                  </label>
                  <Field
                      type="text"
                      name="note_key"
                      className={
                          "pagesAuth form-control" +
                          (errors.note_number && touched.note_number
                              ? " is-invalid"
                              : "")
                      }
                  />
                  <ErrorMessage
                      name="note_number"
                      component="div"
                      className="invalid-feedback"
                  />
                </div>
                <div className="form-group col d-flex align-items-end">
                  {loader ? (
                      <i className="fa fa-2x fa-spinner fa-pulse"></i>
                  ) : (
                      <button type="submit" className="btn btn-primary">
                        {i18n.t("defaults:send")}
                      </button>
                  )}
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}

const updateStatus = async (props, status) => {

  if(status == 'unavailable'){

    try {
      await api.put("/order/" + props.order.id + "/confirm-order", {
        status: false,
      });
      props.ordersReload();
      props.changeOrderState("unavailable");
    } catch (err) {
      popUp("error", "Oh No...", err.message);
    }

  } else {

    try {
      await api.put("/order/" + props.order.id + "/confirm-order", {
        status: true,
      });
      props.ordersReload();
      props.changeOrderState("processing");
    } catch (err) {
      popUp("error", "Oh No...", err.message);
    }

  }




};

const Details = (props) => {
  const { order, onHide, changeOrderState } = props;

  const { t } = useTranslation("orders");

  const changeStep = [
    "pending",
    "processing",
    "pending_payment",
    "completed",
    "canceled",
    "awaiting_confirmation",
    "billed"
  ];

  return (
      <Modal size="xl" show={true} onHide={onHide} centered>
        <Modal.Header className="border-0" closeButton>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm">
                <h5>Pedido #{order.id}</h5>
              </div>
              {/* <div className="col-lg-8 col-sm">
                            <h5>Status: <span className="badge badge-warning">aguardando confirmação</span></h5>
                        </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm">
                {order.status == "unavailable" ? (
                    <ProgressBar
                        striped
                        variant="danger"
                        now={progress(order.status)}
                    />) : (

                    <ProgressBar
                        striped
                        variant="success"
                        now={progress(order.status)}
                    />)}

              </div>
            </div>
            <VaryingDetails {...props} />
            {console.log(order)}
            <div className="row mt-5">
              <div className="col">
                <p className="mb-1">
                  <strong> {t("modalOrder.customerData")}</strong>
                </p>
                <p className="mb-0">
                  {order.shipping_address.first_name}{" "}
                  {order.shipping_address.last_name}
                </p>
                <p className="mb-0">
                  <strong>
                    {" "}
                    {t("defaults:documentFormatPerson")} /{" "}
                    {t("defaults:documentFormatCompany")}:
                  </strong>{" "}
                  {order.customer_vat_number}
                </p>
                <p className="mb-0">
                  {order.shipping_address.address},{" "}
                  {order.shipping_address.number},{" "}
                  {order.shipping_address.neighborhood}
                </p>
                <p>{order.shipping_address.complement}</p>
                <p className="mb-0">
                  {order.shipping_address.city} - {order.shipping_address.state}
                </p>
                <p className="mb-0">
                  <strong> {t("defaults:postCode")}:</strong>{" "}
                  {order.shipping_address.postcode}
                </p>

                <p className="mb-0">
                  {" "}
                  <strong> {t("defaults:phone")}:</strong>{" "}
                  {order.shipping_address.phone}
                </p>

                <hr />

                <p className="mb-1">
                  <strong> {t("defaults:send")}</strong>
                </p>
                <p className="mb-0">
                  {" "}
                  <strong> {t("modalOrder.shippingCompany")}:</strong>{" "}
                  {order.shipping_company}
                </p>
                <p className="mb-0">
                  {" "}
                  <strong> {t("modalOrder.subTotal")}:</strong> {order.sub_total}
                </p>
                <p className="mb-0">
                  <strong> {t("modalOrder.totalShipping")}:</strong>{" "}

                  {currencyFormat(parseFloat(order.shipping_price))}
                </p>

                <p className="mb-0">
                  <strong> {t("defaults:total")}:</strong>{" "}
                  {currencyFormat(parseFloat(order.grand_total))}{" "}
                </p>
              </div>
              <div className="col">
                <p className="mb-0">
                  {" "}
                  <strong> {t("modalOrder.billingFor")}:</strong>
                </p>
                <p className="mb-0">
                  {order.billing_address.first_name}{" "}
                  {order.billing_address.last_name}
                </p>
                <p className="mb-0">
                  {order.billing_address.address}, {order.billing_address.number},{" "}
                  {order.billing_address.neighborhood}
                </p>
                <p className="mb-0">
                  {order.billing_address.city} - {order.billing_address.state}
                </p>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className="table mb-0">
            <thead>
            <tr>
              <th>{t("modalOrder.product")}</th>
              <th>{t("modalOrder.productCode")}</th>

              <th>{t("modalOrder.quantity")}</th>

              <th>{t("modalOrder.unitPrice")}</th>
            </tr>
            </thead>
            <tbody>
            {order.items.map((item, index) => (
                <tr key={index}>
                  <th scope="row">
                    {item.product && (
                        <img
                            src={item.product.images[0].original_image_url}
                            alt={item.name}
                            className="thumb-lg mr-2"
                        />
                    )}

                    {item.name}
                  </th>
                  <td>{item.sku}</td>
                  <td>{item.qty}</td>
                  <td>{item.price}</td>
                </tr>
            ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
  );
};

export default Details;
