import React, { useEffect, useState } from "react";
import msShipping from "../../Services/ms-shipping";

import "./ConfigFrete.css";
import "bootstrap/dist/css/bootstrap.min.css";
import popUp from "../../Services/Utils/swalPopup";
import NavBarConfig from "./Includes/NavBarConfig";
import { useUserData } from "../../Contexts/hooks/useUserData";
import history from "../../history";




function ConfigFrete() {
  const { userData } = useUserData();
  const [ activeshippgins, setActiveShippgins] = useState();
  const [ activeforsellers, setActiveForSellers] = useState();
  const [token, setToken] = useState();
  const [urlExportFreight, setUrlExportFreight] = useState();
  const [inputSelectFile, setInputSelectFile] = useState();
  const [binaryFile, setBinaryFile] = useState();


  const CheckStatus = ( shippingInfo ) => {
    return (
        <label className="switch m5">
          {/*<span>{shippingInfo.shippingInfo.description}</span><p></p>*/}
          <span className="btn btn-info" onClick={(value) => {updateStatusSeller(shippingInfo)}}>Ativar / Desativar </span>
        </label>
    );
  };


  const updateStatusSeller = ( shippingInfo ) => {

    try {
      const updateAssociation = msShipping.put("/api/shipping/seller/association", {
        company_id: userData.companyId,
        method_id: shippingInfo.shippingInfo.method_id,
        active: shippingInfo.shippingInfo.active == true ? 0 : 1
      },{
        headers: {Type: "seller"}
      });

   popUp("info", "Metodo de Entrega Atualizado")

    } catch (err) {
      popUp("error", "Erro", err);

    } finally {
      history.push('/configfrete')
    }
  };


  const handleChangeToken = (e) => {
    setToken(e.target.value);
  };

  const handleUploadChange = (e) => {
    setInputSelectFile(e.target.files[0].name);
    setBinaryFile(e.target.files[0]);
  };

  const handleAssociation = async () => {
    try {
      const updateTokenFrenet = msShipping.post("/api/frenet/seller/store", {
        token: token,
      },{
        headers: {Type: "seller"}
      });

      popUp("info", "Token Frenet Atualizado")

    } catch (err) {
      popUp("error", "Erro", err);

    } finally {

    }
  };

  const handleImportFile = async () => {

    try {

      let data = new FormData();

      data.append("file", binaryFile);

      console.log(data)

      const importFile = msShipping.post("/api/freight-table/seller/import", data,{
        headers: {Type: "seller"}
      });
      popUp("success", "Sucesso", "Tabela de Frete importada com sucesso!");
    } catch (err) {
      popUp("error", "Erro", err.response.data.message);
    } finally {
    }
  };


  useEffect(() => {
    (async function () {

      // List Actives in Company
      try {

        const listedactiveshippings = await msShipping.get("/api/shipping/seller/list-active-company/" + userData.companyId, {
          headers: {Type: "seller"}
        });

        setActiveShippgins(listedactiveshippings.data.data)

      } catch (err) {
        popUp("error", "Erro", err);
      } finally {

      }
      // List Actives in Seller
      try {

        const listedactiveshippingsforseller = await msShipping.get("/api/shipping/seller/" + userData.companyId, {
          headers: {Type: "seller"}
        });

        setActiveForSellers(listedactiveshippingsforseller.data.data)

      } catch (err) {
        popUp("error", "Erro", err);
      } finally {

      }

      // Check Exists - Frenet Token
      try {
        const tokenfrenet = await msShipping.get("/api/frenet/seller", {
          headers: {Type: "seller"}
        });

        setToken(tokenfrenet.data.token)

      } catch (err) {
        popUp("error", "Erro", err);
      } finally {
      }

      //Export file tabela de frete
      try {
        const response = await msShipping.get("/api/freight-table/seller/export", {
          headers: {Type: "seller"}
        });

        setUrlExportFreight(response.data.url);

      } catch (err) {
        popUp("error", "Erro", err.response.data.message);
      } finally {
      }

    })();
  }, []);

  return(
      <div className="wrapper">
        <div className="container-fluid">
          <div className="container-config config-frete">
            <NavBarConfig />
            <div className="container ">
              <div className="row">
                {

                  activeforsellers?.map((shippgins) => (
                      <div key={shippgins.method_id} className=" col-12 col-md-4" style={{ "height": "250px"}}>
                        <h4>{shippgins.title}</h4>
                        <div className="text-left pl-0">
                          <CheckStatus
                              shippingInfo={shippgins}
                          />
                        </div>
                        {shippgins.active? (<span className="btn-outline-success">Ativo</span>) : <span className="btn-outline-warning">Desativado</span>}

                        {shippgins.method_code == 'frenet' ? (

                        <div className="p-0"><p></p>
                          <p>
                            Insira seu token abaixo:
                          </p>
                          <input
                              type="text"
                              id="exampleForm2"
                              className="form-control"
                              value={token}
                              onChange={handleChangeToken}
                          />
                          <button
                              type="button"
                              className="btn btn-secondary btn-sm mt-2 mb-2 float-right"
                              onClick={handleAssociation}
                          >
                            Atualizar Token
                          </button>
                        </div>
                        ):""}

                        {shippgins.method_code == 'freight-table' ?  (
                            <><p></p>
                              <p>
                                <a
                                    href="https://tech-z.s3.sa-east-1.amazonaws.com/freight-table_model.xls"
                                    className="text-primary"
                                >
                                  <i className="fas fa-download"></i> &nbsp;Planilha modelo


                                </a>&nbsp;&nbsp;
                                <a className="text-primary" href={urlExportFreight} >
                                  <i className="fas fa-download"></i> &nbsp;Download tabela existente
                                </a>
                              </p>
                              <p>
                                <div className="custom-file">
                                  <input
                                      type="file"
                                      className="custom-file-input"
                                      id="customFileLang"
                                      lang="es"
                                      onChange={handleUploadChange}
                                  />
                                  <label
                                      className="custom-file-label"
                                      htmlFor="customFileLang"
                                  >
                                    {inputSelectFile}
                                  </label>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-sm mt-2 float-right"
                                    onClick={handleImportFile}
                                >
                                  Enviar
                                </button>

                              </p>

                              <p>

                              </p>
                              <p>


                              </p>
                            </>
                        ) : ("")}

                      </div>
                  ))}

              </div>
            </div>
          </div>
        </div>
      </div>

  );
}

export default ConfigFrete;
