import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

// import { Container } from './styles';

function NavBarConfig() {
  const { t } = useTranslation("components");

  return (
    <div className="card col-3">
      <div>
        <span>
          <Link to={"/configloja"}>
            <span>{t("sidebar.storeProfile")}</span>
          </Link>
        </span>
        <span>
          <Link to={"/configuracoes"}>
            <span>{t("sidebar.financialConfig")}</span>
          </Link>
        </span>
        <span>
          <Link to={"/configfrete"}>
            <span>{t("sidebar.trackingConfig")}</span>
          </Link>
        </span>
        {/*    <span>
          <Link to={"/associacoes"}>
            <span>Associações</span>
          </Link>
        </span> */}
      </div>
    </div>
  );
}

export default NavBarConfig;
