import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./Includes/layout";
import FormBox from "../../Components/Auth/FormBox/formBox";
import LogoCazco from "../../assets/images/logo_cazco.svg";
import Navbar from "./Includes/Navbar/navbar";
import "./Includes/Loader/Loader.css";
import LoginForm from "../../Components/Auth/LoginForm/loginForm";
import history from "../../history";
import { useTranslation } from "react-i18next";
import popUp from "../../Services/Utils/swalPopup";

import { Col } from "react-bootstrap";

export default function Login() {
  const [query, setquery] = useState();

  const { t } = useTranslation("home");

  useEffect(() => {
    let query = new URLSearchParams(history.location.search);

    setquery(query);

    const RedirectLogin = () => {
      if (localStorage.getItem(process.env.REACT_APP_NAME) !== null) {
        history.push("/dashboard");
      }
    };

    RedirectLogin();
  }, []);

  useEffect(() => {
    if (query) {
      const mp = query.get("mp");
      const nameMp = query.get("store");

      if (mp) {
        if (mp === "true") {
          popUp(
            "success",
            "Assosiação do Mercado pago",
            `${nameMp}, sua assosiação foi concluída com sucesso`
          );
        } else {
          popUp(
            "error",
            "Assosiação do Mercado pago",
            `${nameMp}, ocorreu um erro na sua assosiação <br><br>
          
           <a  style="color: #fff; padding: 5px 10px; margin-top: 1rem; background: #F27326" href="mailto:ti@cazco.digital"> Clique aqui e entre em contato conosco</a>`
          );
        }

        history.push("/");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, query]);

  return (
    <Layout>
      <div className="pagesAuth accountbg"></div>

      <Navbar />
      <FormBox>
        <div className=" mt-4 d-flex" id="cardLogoTexto">
          <Col xs={4}>
            <Link to="/" className="logo-admin" id="logo">
              <img src={LogoCazco} height="50" alt="logo" />
            </Link>
          </Col>
          <Col xs={8}>
            <div className=" text-right" id="texto">
              <h4 className="pagesAuth font-18 m-b-5 text-bold text-dark">
                {t("welcome")}
              </h4>
              <p className="pagesAuth font-12 text-dark">{t("loginAdvise")} </p>
            </div>
          </Col>
        </div>

        <div className="px-3 ">
          <LoginForm />
        </div>

        <div className="pagesAuth form-group mt-2 mb-3 row">
          <div className="col-12 text-center">
            <Link to={"/reset-password"} className="text-muted">
              <i className="text-muted mdi mdi-lock mr-2"></i>
              {t("forgotPassword")}{" "}
            </Link>
          </div>
        </div>
      </FormBox>
    </Layout>
  );
}
