import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Includes/layout";
import FormBox from "../../Components/Auth/FormBox/formBox";
import Navbar from "./Includes/Navbar/navbar";
import "./Includes/Loader/Loader.css";
import LogoCazco from "../../assets/images/logo_cazco.svg";
import RegisterForm from "../../Components/Auth/RegisterForm/registerForm";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Register() {
  const { t } = useTranslation("home");

  return (
    <Layout>
      <div className="pagesAuth accountbg"></div>
      <Navbar />
      <FormBox>
        <div className=" mt-4 d-flex" id="cardLogoTexto">
          <Col xs={4}>
            <Link to="/" className="logo-admin" id="logo">
              <img src={LogoCazco} height="50" alt="logo" />
            </Link>
          </Col>
          <Col xs={8}>
            <div className=" text-right" id="texto">
              <h4 className="pagesAuth font-18 m-b-5 text-bold text-dark">
                {t("welcome")}
              </h4>
              <p className="pagesAuth font-12 text-dark">{t("loginAdvise")} </p>
            </div>
          </Col>
        </div>

        <div className="p-3 mt-2">
          <RegisterForm />
        </div>

        <div className="pagesAuth form-group mt-2 mb-3 row">
          <div className="col-12 text-center">
            <Link to={"/reset-password"} className="text-muted">
              <i className="text-muted mdi mdi-lock mr-2"></i>
              {t("forgotPassword")}{" "}
            </Link>
          </div>
        </div>
      </FormBox>
    </Layout>
  );
}

export default Register;
