import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../../../../Services/api";
import NumberFormat from "react-number-format";

import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export default function Stock(props) {
  const { handleSubmit, register, control } = useForm();
  const { t } = useTranslation("catalog");

  const userData = JSON.parse(Cookies.get("userData"));

 
  const { companyId } = userData;

  const {
    onHide,
    sku_vendor,
    stock,
    productName,
    img,
    offerUpdate,
    product_id,
    price,

    offer_id,

    status,
    processing_days,
  } = props;
  const [stateStock, setStateStock] = useState(stock);
  const [loader, setLoader] = useState(false);

  async function onSubmit(data) {
    setLoader(true);
    if (typeof data.price === "string") {
      data.price = data.price.replace(/,/g, "");
      data.price = parseFloat(data.price);
    }

    if (sku_vendor.length < 1) {
      alert("Sku do produto não pode ser vazio.");
      return false;
    }

    if (stateStock.length < 0) {
      alert("Estoque não pode ser negativo.");
      return false;
    }

    /* if (statePrice.length <= 0) {
      alert("O preço não pode ser zero.");
      return false;
    } */

    const offerUpdated = {
      sku_vendor: data.sku_vendor,
      price: data.price,
      stock: data.stock,
      status: data.status ? "true" : "false",
      processing_days: data.processing_days,
      promotional_price: "",
      promotional_percentage: "",
      date_initial: "",
      date_final: "",
      product_id: product_id,
      company: [companyId],
    };

    const itemUpdateTable = {
      price: data.price,
      sku_vendor: data.sku_vendor,
      status: data.status ? 1 : 0,
      stock: data.stock,
      processing_days: data.processing_days,
    };
    try {
      await api.put("/offer/update/" + offer_id, offerUpdated);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }

    onHide();
    offerUpdate(itemUpdateTable, offer_id);
  }

  return (
    <Modal size="lg" show={true} onHide={onHide} centered>
      <div className="container">
        <div className="row">
          <div className="col-lg-11 col-sm title">
            <h5>{productName}</h5>
          </div>
          <Modal.Header className="border-0" closeButton></Modal.Header>
        </div>
      </div>
      <Modal.Body>
        <div className="table mb-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container edit-offers">
              <div className="edit-img d-flex align-items-center justify-content-center">
                {img ? (
                  <img src={img} alt={productName} />
                ) : (
                  <div className="no-photo">
                    <i class="fas fa-unlink"></i>
                  </div>
                )}

                <div
                  className="text-center"
                  style={{ color: "#ff8031", textDecoration: "underline" }}
                ></div>
              </div>
              <div className="edit-infos">
                <div className="edit-names">
                  <div className="input-group">
                    <span className="input-group-btn input-group-append">
                      {t("modalOffer.sellerCode")}:{" "}
                      <input
                        type="text"
                        defaultValue={sku_vendor}
                        ref={register}
                        name="sku_vendor"
                        /*    onChange={(e) => setStateSkuVendor(e.target.value)} */
                        className="form-control text-center text-uppercase"
                        style={{
                          width: "150px",
                          marginLeft: "20px",
                          height: "30px",
                        }}
                      />
                    </span>
                  </div>
                </div>

                <div className="edit-numbers">
                  <div>
                    <h6> {t("modalOffer.availableStock")} </h6>
                    <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                      <span className="input-group-btn input-group-prepend">
                        <button
                          className="btn btn-primary btn-orange bootstrap-touchspin-down"
                          type="button"
                          onClick={() => setStateStock(stateStock - 1)}
                        >
                          -
                        </button>
                      </span>
                      <input
                        type="number"
                        name="stock"
                        ref={register}
                        value={stateStock}
                        onChange={(e) => setStateStock(e.target.value)}
                        className="form-control text-center"
                      />
                      <span className="input-group-btn input-group-append">
                        <button
                          className="btn btn-primary btn-orange bootstrap-touchspin-up"
                          type="button"
                          onClick={() => setStateStock(stateStock + 1)}
                        >
                          +
                        </button>
                      </span>
                    </div>
                  </div>
                  <div>
                    <h6>{t("modalOffer.yourPrice")}</h6>
                    <div>
                      <>
                        <div className="d-flex box-price align-items-center mt-4 pt-1">
                          <div className="mr-2 mb-0">
                            {t("defaults:currency")}
                          </div>
                          <Controller
                            as={NumberFormat}
                            className="pl-2"
                            decimalScale={2}
                            readOnly={userData.companyName.toLowerCase().replace(' ', '_') === 'loja_deca' ? true : false}
                            thousandSeparator={","}
                            decimalSeparator={"."}
                            defaultValue={price}
                            fixedDecimalScale={true}
                            name="price"
                            allowNegative={false}
                            control={control}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </div>

                <div className=" d-flex align-items-center">
                  <span> {t("modalOffer.processingDays")}: </span>
                  <input
                    type="number"
                    defaultValue={processing_days}
                    className="ml-2 form-control processing-input text-center"
                    ref={register}
                    name="processing_days"
                  />
                </div>

                {loader ? (
                  <i className="fa fa-2x fa-spinner fa-pulse"></i>
                ) : (
                  <div className="row mt-1 align-items-center">
                    <div className="col-8 col-sm d-flex  ">
                      <Form.Check
                        className="m-0"
                        name="status"
                        type="switch"
                        defaultChecked={status}
                        ref={register}
                        id="custom-switch"
                        label={t("modalOffer.offerStatus")}
                      />

                      {/* <button
                      type="button"
                      className="btn bg-info text-white btn-outline-info"
                      onClick={() => startOffer()}
                    >
                      <h6>ATIVAR OFERTA</h6>
                    </button> */}
                    </div>

                    <div className="col-4 d-flex m-0 justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-success align-items-center"
                        /*  onClick={() => updateOffer()} */
                      >
                        <h6 className="m-0 p-0">{t("defaults:save")} </h6>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
