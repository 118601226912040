import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import i18n from "../../i18n";
import api from "../../Services/api";
import popUp from "../../Services/Utils/swalPopup";

export default function DetailsTracking(props) {
  const [loader, setLoader] = useState();
  const { register, control, handleSubmit, setValue } = useForm();

  const { order, ordersReload, loadDataModal } = props;


  const sendReadyToDelivery = async  () => {
    try {
      setLoader(true);
      const response = await api.put("/order/" + order.id + "/tracking", []);
      loadDataModal(order.id);
      ordersReload();
    } catch (err) {
      popUp("error", "Oh No...", err.response.data.message);
    } finally {
      setLoader(false);
    }
  }

  const sendTracking = async (data) => {

    const { delivery_time } = data;

    var date = delivery_time;
    var datearray = date.split("/");
    var newdate = datearray[1] + "/" + datearray[0] + "/" + datearray[2];
    newdate = new Date(newdate) - new Date();

    const days = Math.ceil(newdate / (1000 * 60 * 60 * 24));

    if(isNaN(days) == true){
      popUp("error", "Oh No...", 'Data de entrega é Inválida');
      return false;
    }
 
    data.delivery_time = days;

    try {
      setLoader(true);
      
      const response = await api.put("/order/" + order.id + "/tracking", data);
      // popUp("success", "Oh Yes...", response.data.message);
      loadDataModal(order.id);
      ordersReload();
    } catch (err) {
      popUp("error", "Oh No...", err.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (order.shipping_method === "zippin") {
      (async function () {
        setLoader(true);

        const data = {
          order_id: order.id,
        };

        try {
          const { data: response } = await api.post("/zippin/tracking", data);

          setValue("code_tracking", response?.tracking);
        } finally {
          setLoader(false);
        }
      })();
    }
  }, [order.id, order.shipping_method, props, setValue]);

  /*  useEffect(() => {
    (async function () {
      const data = {
        order_id: order.id,
      };
      try {
        const { data: response } = await api.post("/zippin/tag", data);
      } finally {
      }
    })();
  }, [order.id]); */

  return (
    <div className="row mt-3">
      <div className="col-lg-12 col-sm">

        {order.shipping_method == 'withdraw-in-store' ? (

                <button type="submit" className="btn btn-primary" onClick={sendReadyToDelivery}>
                  PRONTO PARA RETIRADA
                </button>

        ) : (

        <form onSubmit={handleSubmit(sendTracking)}>
          <div className="row">
            <div className="col-sm-5 form-group">
              <label>{i18n.t("orders:modalOrder.shippingCompany")}</label>
              <input
                type="text"
                className={"form-control"}
                name="shipping_company"
                // required
                ref={register({ required: true })}
              />
            </div>
            <div className="col-sm-5 form-group">
              <label>{i18n.t("orders:modalOrder.trackingCode")}</label>
              <input
                type="text"
                className={"form-control"}
                // required
                name="code_tracking"
                ref={register({ required: true })}
              />
            </div>
            <div className="col form-group">
              <label></label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5 form-group">
              <label>{i18n.t("orders:modalOrder.urlTracking")}</label>
              <input
                type="text"
                className={"form-control"}
                // required
                name="url_tracking"
                ref={register({ required: false })}
              />
            </div>

            <div className="col-sm-5 form-group">
              <label>{i18n.t("orders:modalOrder.previsionToArrive")}:</label>

              <Controller
                as={<ReactInputMask />}
                // required
                mask={"99/99/9999"}
                minLength={10}
                name="delivery_time"
                control={control}
              />
            </div>
            <div className="form-group col d-flex align-items-end">
              {loader ? (
                <i className="fa fa-2x fa-spinner fa-pulse"></i>
              ) : (
                <button type="submit" className="btn btn-primary">
                  {i18n.t("defaults:send")}
                </button>
              )}
            </div>
          </div>
        </form>

        )}
      </div>
    </div>
  );
}
