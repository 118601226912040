import React, { useEffect, useState } from "react";
import banksList from "../../Services/BankList";
import api from "../../Services/api";
import "./Configuracoes.css";
import { useForm, Controller } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Pages/Auth/Includes/Loader/Loader.css";
import Select from "react-select";
import popUp from "../../Services/Utils/swalPopup";
import MercadoPagoLogo from "../../assets/images/mercado-pago-logo.png";

import NavBarConfig from "./Includes/NavBarConfig";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../Contexts/hooks/useUserData";
import { Button } from "react-bootstrap";

const language = process.env.REACT_APP_LANGUAGE;

export default function Configuracoes() {
  const [loader, setLoader] = useState();
  const [bankData, setBankdata] = useState([]);
  const [loadingUrl, setLoadingUrl] = useState();
  const [types, setTypes] = useState();

  const { userData } = useUserData();

  const { register, handleSubmit, control, setValue } = useForm();

  const { t } = useTranslation("config");

  const handleMercadoPagoUrl = async () => {
    setLoadingUrl(true);
    try {
      const response = await api.post("/mercado-pago/link-url", {
        company: userData.companyId,
      });

      window.open(response.data.url, "_blank");
    } catch (err) {
      popUp("error", "Oops...", err.response.data.message);
    } finally {
      setLoadingUrl(false);
    }
  };

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);
        const response = await api.get("/bank-account");
        setValue(
          "bank_code",
          banksList.find((option) => option.value === response.data.bank_code)
        );
        setBankdata(response.data);
      } catch (err) {
        popUp("error", "Oops...", err.response.data.message);
      } finally {
        setLoader(false);
      }
    })();
  }, [setValue]);

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);
        const response = await api.get("/bank-account/types");

        setTypes(response.data);
      } catch (err) {
        popUp("error", "Oops...", err.response.data.message);
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  const handleFinancialData = async (data) => {
    setLoader(true);

    data.bank_code = data.bank_code.value;

    try {
      const response = await api.post("/bank-account/store", data);

      popUp("success", "Oh Yess...", response.data.message);
    } catch (err) {
      popUp("error", "Oh No...", err.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#323a4e",
      color: "#fff",

      border: "1px solid #5e6882",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: "#323a4e",
      ":hover": {
        backgroundColor: "#2a3142",
      },
      color: "#fff",
    }),
    input: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#323a4e",
      borderColor: "#323a4e",
    }),
    /*     input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }), */
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          {/* <div className="row"> */}
          <div className="container-config">
            <NavBarConfig />

            <div className="card col-8">
              <div>
                {language === "es" && (
                  <>
                    <div className="text-center py-5">
                      <img src={MercadoPagoLogo} alt="Mercado pago" />

                      <div className="mt-4 text-center">
                        {loadingUrl ? (
                          <i className="fa fa-2x fa-spinner fa-pulse"></i>
                        ) : (
                          <Button onClick={handleMercadoPagoUrl}>
                            Asociado al mercado pago
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {language !== "es" && (
                  <fieldset disabled={loader ? true : false}>
                    <form
                      className="form-config"
                      onSubmit={handleSubmit(handleFinancialData)}
                    >
                      <label className="mt-3">{t("form.bankCode")}</label>

                      {/*      <input
                      name="bank_code"
                      ref={register}
                      defaultValue={bankData.bank_code}
                      type="text"
                      maxLength="3"
                    /> */}

                      <Controller
                        as={<Select />}
                        required
                        placeholder={t("form.selectYourBank")}
                        options={banksList}
                        styles={colourStyles}
                        value={bankData.bank_code}
                        name="bank_code"
                        isClearable
                        control={control}
                        onChange={([selected]) => selected}
                      />

                      <div className="row">
                        <div className="col-md-6">
                          <label className="mt-3" htmlFor="agence">
                            {t("form.agence")}
                          </label>
                          <input
                            name="agence"
                            type="text"
                            ref={register}
                            defaultValue={bankData.agence}
                            maxLength="4"
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="mt-3" htmlFor="agence_dv">
                            {t("form.agenceDigit")}
                          </label>
                          <input
                            name="agence_dv"
                            type="text"
                            ref={register}
                            defaultValue={bankData.agence_dv}
                            maxLength="4"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="mt-3" htmlFor="account">
                            {t("form.account")}
                          </label>
                          <input
                            name="account"
                            ref={register}
                            defaultValue={bankData.account}
                            type="text"
                            maxLength="15"
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="mt-3" htmlFor="account_dv">
                            {t("form.accountDigit")}
                          </label>
                          <input
                            name="account_dv"
                            type="text"
                            ref={register}
                            defaultValue={bankData.account_dv}
                            maxLength="4"
                          />
                        </div>
                      </div>

                      <label className="mt-4 w-100" htmlFor="">
                        {t("form.accountType")}:
                      </label>
                      <select
                        name="type"
                        ref={register}
                        defaultValue={bankData.type}
                        className="mb-4 w-100"
                        id=""
                      >
                        {types &&
                          Object.entries(types).map(([key, value]) => (
                            <option value={key}>{value}</option>
                          ))}
                      </select>

                      <label htmlFor="legal_name">{t("defaults:name")}</label>
                      <input
                        name="legal_name"
                        type="text"
                        maxLength="70"
                        defaultValue={bankData.name}
                        ref={register}
                      />
                      <label className="mt-3" htmlFor="document_number">
                        {t("form.documentNumber")}
                      </label>
                      <input
                        name="document_number"
                        type="text"
                        maxLength="14"
                        ref={register}
                        defaultValue={bankData.document_number}
                      />

                      {loader ? (
                        <div>
                          <i className="fa fa-2x fa-spinner fa-pulse"></i>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mr-2 mt-5"
                          style={{
                            backgroundColor: loader ? "#1f2431" : "",
                          }}
                        >
                          {t("defaults:save")}
                        </button>
                      )}
                    </form>
                  </fieldset>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* end row */}
        </div>
        {/* end container-fluid */}
      </div>
    </>
  );
}
