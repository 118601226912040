import React, { useEffect, useState } from "react";
import NavBarConfig from "./Includes/NavBarConfig";

import { useForm } from "react-hook-form";
import axios from "axios";
import api from "../../Services/api";
import popUp from "../../Services/Utils/swalPopup";

import "../../App.css";

// import { Container } from './styles';

function SellerAssociations() {
  const [sellerInfo, setSellerInfo] = useState();
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, watch } = useForm();

  const stores = watch("stores");

  async function associateStore(data) {
    setLoading(true);
    try {
      const { name, email, phone, id } = sellerInfo;

      data = {
        ...data,
        seller_name: name,
        seller_phone: phone,
        seller_email: email,
        seller_id: id,
      };

      data = JSON.stringify(data);

      await axios.post("http://localhost:5000/", data);

      popUp("success", "Lojas associadas com sucesso");
    } catch (err) {
      popUp("error", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get("/get");

        setSellerInfo(response.data);
      } catch (err) {
        alert(err.response.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="wrapper">
      <div className="container-fluid">
        <div className="container-config">
          <NavBarConfig />

          <form
            onSubmit={handleSubmit(associateStore)}
            className="form-store col-8 row"
          >
            <div className="col-md-4">
              <label className="card p-4">
                <h3> Amanco</h3>
                <input
                  value="Amanco"
                  ref={register}
                  name="stores"
                  type="checkbox"
                />
              </label>
            </div>

            <div className="col-md-4">
              <label className="card p-4">
                <h3> Deca</h3>
                <input
                  value="Deca"
                  ref={register}
                  name="stores"
                  type="checkbox"
                />
              </label>
            </div>

            <div className="col-md-4">
              <label className="card  p-4">
                <h3> Coral</h3>
                <input
                  value="Coral"
                  ref={register}
                  name="stores"
                  type="checkbox"
                />
              </label>
            </div>

            <div className="text-center col-12">
              {loading ? (
                <i className="fa fa-2x fa-spinner fa-pulse"></i>
              ) : !stores.length ? (
                ""
              ) : (
                <div className="">
                  <button type="submit">Associar</button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SellerAssociations;
