import axios from "axios";
import { getToken } from "./auth";
import popUp from "./Utils/swalPopup";

import history from "../history";

const url = process.env.REACT_APP_PLATAZ_URL + "/api/seller";

const api = axios.create({
  baseURL: /* proxyurl + */ url,
  /*   timeout: 100000, */
});

api.interceptors.response.use(
  (response) => {
    // response data

    return response;
  },
  function (error) {
    // response error

    const errorMessage =
      error.response.data.message ?? error.response.data.error;

    if (error.response.status === 400) {
      popUp("error", "Ops...", errorMessage);
    }

    if (error.response.status === 401) {
      popUp("error", "Ops...", errorMessage);
      localStorage.removeItem(process.env.REACT_APP_NAME);
      sessionStorage.removeItem("user_attr");
      history.push("/");
    }

    if (error.response.status === 408 || error.code === "ECONNABORTED") {
      console.log(`A timeout happend on url ${error.config.url}`);
      popUp(
        "error",
        "Opss",
        "Nossos servidores estão com problema, tente recarregar"
      );
    }

    if (error.response.status === 500) {
      popUp(
        "error",
        "Opss",
        "Nossos servidores estão com problema, tente recarregar"
      );
    }

    return Promise.reject(error);
  }
);

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
