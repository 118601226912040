import React, { useCallback, useEffect, useState } from "react";
import { Table, Button, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../Services/api";
import Cookies from "js-cookie";
import popUp from "../../Services/Utils/swalPopup";

export default function ProductsTable({ callBack }) {
  const { t } = useTranslation("catalog");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [searchData, setSearchData] = useState();
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState(1);
  const [lastPage, setLastPage] = useState();

  const userData = JSON.parse(Cookies.get("userData"));
  const { companyId } = userData;

  const { register, handleSubmit, reset } = useForm();

  const columnsProducts = [
    t("defaults:image"),
    t("defaults:name"),
    t("defaults:code"),
    t("tableCatalog.action"),
  ];

  const handleSearchProducts = useCallback(async (page, term) => {
    const search = term?.length ? term : null;

    setLoading(true);
    try {
      const { data: response } = await api.get("/products", {
        params: {
          search,
          except_with_offers: true,
          page: page ?? 1,
        },
      });

      const { data, links, meta } = response;

      const prevPageNumber = links.prev?.split("page=")[1];

      const nextPageNumber = links.next?.split("page=")[1];

      setPrevPage(prevPageNumber ? parseInt(prevPageNumber) : 1);
      setNextPage(nextPageNumber ? parseInt(nextPageNumber) : 1);

      setProducts(data);

      setTotalProducts(meta.total);

      setCurrentPage(meta.current_page);

      setLastPage(meta.last_page);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  async function createOffer(product) {
    const data = {
      sku_vendor: product.sku,
      price: 0,
      stock: 0,
      status: "true",
      processing_days: "2",
      promotional_price: "",
      promotional_percentage: "",
      date_initial: "",
      date_final: "",
      product_id: product.id,

      company: [companyId],
    };

    try {
      const response = await api.post("/offer/store", data);
      popUp("success", "Oh Yess...", response.data.message);

      callBack(product.id);
    } catch (err) {
      if (err.response) {
        popUp("error", "Oh No...", err.response.data.message);
      }
    } finally {
    }

    handleSearchProducts(currentPage, searchData);
  }

  async function handleFieldSearch({ search }) {
    if (!search) {
      setSearchData(null);
      return;
    }

    await handleSearchProducts(1, search);

    setSearchData(search);
  }

  function clearFieldsSearch() {
    reset(["search"]);
    if (!searchData) {
      return;
    }

    setSearchData(null);
    handleSearchProducts();
  }

  useEffect(() => {
    handleSearchProducts();
  }, [handleSearchProducts]);

  return (
    <div>
      <fieldset disabled={loading}>
        <form className="my-4" onSubmit={handleSubmit(handleFieldSearch)}>
          <Col xs={10} className="m-auto">
            <label htmlFor="search">{t("defaults:search")}</label>

            <div
              noGutters
              className="d-flex justify-content-center align-items-center"
            >
              <input
                type="search"
                className="  bg-primary px-3 text-white rounded-left"
                placeholder={t("tableCatalog.inputPlaceHolder")}
                name="search"
                onChange={(e) => !e.target.value && setSearchData(null)}
                ref={register({ required: true })}
              />

              <span className="mx-2">
                <Button className="m-0" type="submit">
                  <i className="fas fa-search"></i>
                </Button>
              </span>

              <Button
                className="m-0"
                type="button"
                onClick={() => clearFieldsSearch()}
              >
                <i className="far fa-trash-alt"></i>
              </Button>
            </div>
          </Col>
        </form>
      </fieldset>
      {loading ? (
        <div className="text-center">
          <i className="fa fa-2x fa-spinner fa-pulse"></i>
        </div>
      ) : (
        <>
          <div className="my-3 text-right h6">
            {t("defaults:results")}: {totalProducts}
          </div>
          <Table striped hover>
            <thead>
              <tr>
                {columnsProducts.map((column) => (
                  <th key={column}>{column}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {products?.map((product) => (
                <tr key={product.id}>
                  <td>
                    <img
                      src={product.base_image.small_image_url}
                      alt={product.name}
                    />
                  </td>
                  <td>{product.name}</td>
                  <td>{product.sku}</td>

                  <td>
                    <Button onClick={() => createOffer(product)}>
                      adicionar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex btns-nav justify-content-end align-items-center">
            <div className="mr-4">
              {t("defaults:currentPage")}: <strong> {currentPage}</strong>
            </div>

            <Button
              className="bg-none rounded-circle mr-1 d-flex align-items-center justify-content-center"
              onClick={() => handleSearchProducts(1, searchData)}
              disabled={currentPage === 1 ? true : false}
            >
              <i className="fas fa-angle-double-left h5 m-0"></i>
            </Button>

            <Button
              onClick={() => handleSearchProducts(prevPage, searchData)}
              className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
              disabled={currentPage <= prevPage ? true : false}
            >
              <i className="fas fa-angle-left h5 m-0"></i>
            </Button>

            <Button
              onClick={() => handleSearchProducts(nextPage, searchData)}
              className="rounded-circle d-flex align-items-center justify-content-center "
              disabled={currentPage >= nextPage ? true : false}
            >
              <i className="fas fa-angle-right h5 m-0"></i>
            </Button>

            <Button
              className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
              disabled={currentPage === lastPage ? true : false}
              onClick={() => handleSearchProducts(lastPage, searchData)}
            >
              <i className="fas fa-angle-double-right h5 m-0"></i>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
