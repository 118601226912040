import React, { useCallback, useEffect, useState } from "react";
import "./Catalogo.css";
import { Modal, Table, Button, Col, Row } from "react-bootstrap";
import "@mdi/font/css/materialdesignicons.min.css";
import Details from "../Main/Includes/Catalogo/Stock";
import api from "../../Services/api";
import currencyFormat from "../../Services/Utils/currencyFormat";
import "../Auth/Includes/Loader/Loader.css";

import popUp from "../../Services/Utils/swalPopup";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import ProductsTable from "../../Components/ProductsTable";

export default function Catalogo() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const [details, setDetails] = useState(false);
  const [stock, setStock] = useState(0);
  const [productName, setProductName] = useState("");
  const [img, setImg] = useState("");
  const [product_id, setProduct_id] = useState("");
  const [processing_days, setProcessing_days] = useState("");
  const [price, setPrice] = useState("");
  const [status, setStatus] = useState("");
  const [offer_id, setOffer_id] = useState("");
  const [sku_vendor, setSku_vendor] = useState();
  const [inputSelectFile, setInputSelectFile] = useState("");
  const [binaryFile, setBinaryFile] = useState(null);
  const [currentPage, setCurrentPage] = useState();
  const [totalOffers, setTotalOffers] = useState();
  const [searchData, setSearchData] = useState();
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState(1);
  const [loadingUpload, setloadingUpload] = useState();
  const [lastPage, setLastPage] = useState();
  const [updateOffers, setUpdateOffers] = useState();
  const [uploading, setUploading] = useState("esconder");
  const [noOffers, setNoOffers] = useState(false);

  const [offers, setOffers] = useState([]);

  const { t } = useTranslation("catalog");

  const { register, reset, handleSubmit } = useForm();

  const columnsCatalog = [
    t("defaults:image"),
    t("defaults:name"),
    t("defaults:brand"),
    t("tableCatalog.branCode"),
    t("tableCatalog.sellerCode"),
    t("defaults:status"),
    t("tableCatalog.price"),
    t("tableCatalog.stock"),
    t("tableCatalog.action"),
  ];

  /*   function filterByValue(array, string) {
    return array.filter((o) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] == "string" &&
          o[k].toLowerCase().includes(string.toLowerCase())
      )
    );
  } */

  const handleSearchOffers = useCallback(async (page = 1, term) => {
    const search = term?.length ? term : '';

    setLoading(true);
    try {
      const { data: response } = await api.get("/offer/list?page=" + page + "&search=" + search);

      const { data, meta, links} = response;

      const prevPageNumber = response.links.prev?.split("page=")[1];

      const nextPageNumber = response.links.next?.split("page=")[1];

      setPrevPage (parseInt(prevPageNumber));
      setNextPage(parseInt(nextPageNumber));

      setOffers(
        data.map((offer) => ({
          ...offer,
          price_formatted: currencyFormat(offer.price),
        }))
      );

      setLastPage(99);

      setTotalOffers(99);

      setCurrentPage(response.meta.current_page);
    } catch (err) {
      console.error(err)
      // setNoOffers(true);

    } finally {
      setLoading(false);
    }
  }, []);

  async function handleFieldSearch({ search }) {
    if (!search) {
      setSearchData(null);
      return;
    }

    await handleSearchOffers(0, search);

    setSearchData(search);
  }
  

  useEffect(() => {
    handleSearchOffers();
  }, [handleSearchOffers, updateOffers]);

  const showModal = () => {
    setisOpen(true);
  };

  const closeModal = () => {
    setDetails(false);
  };

  const hideModal = () => {
    setisOpen(false);
  };

  function clearFieldsSearch() {
    reset(["search"]);
    setNoOffers(false);
    if (!searchData) {
      return;
    }

    setSearchData(null);
    handleSearchOffers();
  }

  const offerUpdate = (dataUpdated, offer_id) => {
    let offerToUpdate = offers.find((offer) => offer.id === offer_id && offer);

    let offerUpdated = Object.assign(offerToUpdate, dataUpdated);

    setOffers([...Object.assign(offers, offerUpdated)]);
  };

  const handleUploadChange = (e) => {
    e.preventDefault();

    setInputSelectFile(e.target.files[0].name);
    setBinaryFile(e.target.files[0]);
  };

  const uploadOffers = async () => {
    setloadingUpload(true);
    try {
      var data = new FormData();

      data.append("file_path", binaryFile);

      await api.post("/offer/import-offers", data);

      popUp("success", "Archivo cargado exitosamente");

      handleSearchOffers();
    } finally {
      setloadingUpload(false);
    }
  };

  const loadDataModal = useCallback((offer) => {
    const product = offer;

    if (product) {
      setStock(product.stock);
      setSku_vendor(product.sku_vendor);
      setProductName(product.product_name);
      setImg(product.images && product.images.small_image_url);
      setProduct_id(product.product_id);
      setPrice(product.price);
      setOffer_id(product.id);
      setStatus(product.status);
      setProcessing_days(product.processing_days);
      setDetails(true);
    }
  }, []);

  const downloadCurrentTable = async () => {
    try {
      const response = await api.get("/offer/export");

      window.open(response.data.url);
    } catch (err) {
      popUp("error", "Oh No...", err.response.data.message);
    } finally {
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="mt-0 header-title text-uppercase">
                    {t("titlePage")}
                  </h4>

                  <div className="d-flex justify-content-end flex-column">

                    <Row
                      noGutters
                      className=" justify-content-between mt-4"
                    >
                      <Col md={6}>
                        <div className="box-out">
                          <div className="box-in">
                            <h3 className="txt-produto">
                              {t("addProduto")}
                            </h3>
                            <button
                              type="button"
                              className="btn btn-primary btn-lg waves-effect waves-light btn-new"
                              style={{ }}
                              onClick={showModal}
                            >
                              {t("addOffer")}
                            </button>
                          </div>
                        </div>



                    {process.env.REACT_APP_LANGUAGE != "ptBr" ? (
                        <div className="box-out">
                          <div className="box-in">
                            <div className="download">
                              <h3 className="txt-produto">
                                {t("addProdutoMassa")}
                              </h3>
                              <a
                                href="https://www.alluzy.com/carga-de-catalogo-de-sellers-ar/"
                                target="_new"
                                className="btn btn-primary btn-lg waves-effect waves-light btn-new large"
                              >
                                {t("downloadTableButton")}
                              </a>
                            </div>
                          </div>
                        </div>

):""}

                      </Col>

                      <Col md={6}>
                        <div className="box-out">
                          <div className="box-in">
                            <h3 className="txt-produto">
                              {t("listaProdutoMassa")}
                            </h3>
                            {t("helpPlanilha")}
                            <div className="mb-3 btdl" onClick={() => setUploading(uploading === 'esconder' ? 'mostrar' : 'esconder')}>
                              <button
                                className="btn btn-primary btn-lg waves-effect waves-light btn-new"
                                onClick={() => downloadCurrentTable()}
                              >
                                {t("downloadModelo")}
                              </button>
                              <div className="uploading">
                                {t("txtUp")}
                              </div>
                            </div>

                            <div className="espaco">
                              <div className="linha">
                              </div>
                            </div>

                            <div className="mb-2">
                              {t("massiveUpload")}
                              <br/>
                              {loadingUpload ? (
                                <i className="fa fa-2x fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center mt-2">
                                    <div className="custom-file w-50">


                                      <label
                                        className="btn-new cinza waves-effect waves-light"
                                        htmlFor="customFileLang"
                                      >
                                        {t("uploadModelo")}
                                      </label>
                                      <input
                                        type="file"
                                        className="custom-file-input"
                                        id="customFileLang"
                                        lang="es"
                                        onChange={handleUploadChange}
                                      />
                                    </div>
                                    <span className="float-left">
                                          {inputSelectFile}
                                        </span>
                                    {inputSelectFile && (
                                      <button
                                        type="button"
                                        className="btn btn-primary ml-2 btn-lg waves-effect waves-light"
                                        style={{ float: "right" }}
                                        onClick={uploadOffers}
                                      >
                                        {t("defaults:upload")}
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <form
                    className="my-4"
                    onSubmit={handleSubmit(handleFieldSearch)}
                  >
                    <Col xs={10} className="m-auto">
                      <label htmlFor="search">{t("defaults:search")}</label>

                      <div
                        noGutters
                        className="d-flex justify-content-center align-items-center"
                      >
                        <input
                          type="search"
                          className="  bg-primary px-3 text-white rounded-left"
                          placeholder={t("tableCatalog.inputPlaceHolder")}
                          name="search"
                          onChange={(e) =>
                            !e.target.value && setSearchData(null)
                                
                          }
                          onKeyUp={(e) =>
                            (e.keyCode == 8 || e.keyCode == 46 ? setNoOffers(false) : "")
                            }

                          ref={register({ required: true })}
                        />

                        <span className="mx-2">
                          <Button className="m-0" type="submit">
                            <i className="fas fa-search"></i>
                          </Button>
                        </span>

                        <Button
                          variant="secondary"
                          className="m-0"
                          type="button"
                          onClick={() => clearFieldsSearch()}
                        >
                          <i className="far fa-trash-alt"></i>
                        </Button>
                      </div>
                    </Col>
                  </form>

                  {loading ? (
                    <div className="text-center">
                      <i className="fa fa-2x fa-spinner fa-pulse"></i>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      {noOffers ? (
                        <div className="h4 mt-5 text-center w-100">
                          Nenhuma oferta encontrata com esse termo.
                          
                        </div>
                      ) : (
                        <>
                          <div className="mb-3 text-right h6">
                            {t("defaults:results")}: {totalOffers}
                          </div>
                          <Table striped hover responsive="xs">
                            <thead>
                              <tr>
                                {columnsCatalog.map((column) => (
                                  <th key={column}>{column}</th>
                                ))}
                              </tr>
                            </thead>

                            <tbody>
                              {offers.map((offer) => (
                                <tr key={offer.product_id}>
                                  <td>
                                    {offer.images ? (
                                      <img
                                        src={offer.images.small_image_url}
                                        alt={offer.product_name}
                                      />
                                    ) : (
                                      <div className="no-photo">
                                        <i class="fas fa-unlink"></i>
                                      </div>
                                    )}
                                  </td>

                                  <td>{offer.product_name}</td>

                                  <td>{offer.attributes.brand}</td>

                                  <td>{offer.attributes.manufacturer_code}</td>

                                  <td>{offer.sku_vendor}</td>

                                  <td>
                                    {offer.status ? (
                                      <span className="completed p-1">
                                        {" "}
                                        {t("filter.active")}
                                      </span>
                                    ) : (
                                      <span className="bg-danger p-1">
                                        {" "}
                                        {t("filter.paused")}
                                      </span>
                                    )}
                                  </td>

                                  <td>{offer.price_formatted}</td>

                                  <td align="center">{offer.stock}</td>

                                  <td>
                                    <Button
                                      onClick={() => loadDataModal(offer)}
                                    >
                                      Editar
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="d-flex justify-content-end">
                            <div className="mr-4">
                              {t("defaults:currentPage")}:{" "}
                              <strong> {currentPage}</strong>
                            </div>

                            <div className="btns-nav d-flex align-items-center">
                              <Button
                                variant="secondary"
                                className="bg-none rounded-circle mr-1 d-flex align-items-center justify-content-center"
                                onClick={() =>
                                  handleSearchOffers(1, searchData)
                                }
                                disabled={currentPage === 1 ? true : false}
                              >
                                <i className="fas fa-angle-double-left h5 m-0"></i>
                              </Button>

                              <Button
                                onClick={() =>
                                  handleSearchOffers(prevPage, searchData)
                                }
                                className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
                                disabled={currentPage <= prevPage}
                              >
                                <i className="fas fa-angle-left h5 m-0"></i>
                              </Button>

                              <Button
                                onClick={() =>
                                  handleSearchOffers(nextPage, searchData)
                                }
                                className="rounded-circle d-flex  mr-1  align-items-center justify-content-center "
                                disabled={currentPage >= nextPage}
                              >
                                <i className="fas fa-angle-right h5 m-0"></i>
                              </Button>

                              <Button
                                variant="secondary"
                                className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
                                disabled={
                                  currentPage === lastPage ? true : false
                                }
                                onClick={() =>
                                  handleSearchOffers(lastPage, searchData)
                                }
                              >
                                <i className="fas fa-angle-double-right h5 m-0"></i>
                              </Button>
                            </div>
                          </div>
                        </>
                        /*  <SearchableTable
                          title="Produtos"
                          columns={columns()}
                          data={dataOffers()}
                          selectAllRowsItem={true}
                          subHeaderComponent={subHeaderComponentMemo}
                          pagination
                          subHeader
                        /> */
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {details && (
        <Details
          onHide={closeModal}
          stock={parseInt(stock)}
          sku_vendor={sku_vendor}
          productName={productName}
          img={img}
          processing_days={processing_days}
          offerUpdate={offerUpdate}
          product_id={product_id}
          price={price}
          offer_id={offer_id}
          status={status}
        />
      )}

      <Modal size="xl" show={isOpen} onHide={hideModal} centered>
        <div className="container">
          <div className="row">
            <div className="col-lg-11 col-sm title">
              <h5> {t("titleModalProducts")}</h5>
            </div>
            <Modal.Header className="border-0" closeButton></Modal.Header>
          </div>
        </div>
        <div></div>
        <Modal.Body style={{ minHeight: "100px" }}>
          <ProductsTable callBack={setUpdateOffers} />
        </Modal.Body>
      </Modal>
    </>
  );
}
