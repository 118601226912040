import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import api from "../../Services/api";
import currencySymbol from "../../Services/Utils/currencySymbol";

import popUp from "../../Services/Utils/swalPopup";

import CurrencyFormat from 'react-currency-format';

var date = new Date();

var year = date.getFullYear();

export default function Dashboard() {
  const [dados, setDados] = useState([]);

  const [loader, setLoader] = useState();

  const { t } = useTranslation("dashboard");

  useEffect(() => {
    (async function () {
      setLoader(true);

      try {
        let { data: response } = await api.get("/dashboard?month_start=01/01/"+ new Date().getFullYear() +"&month_end=30/12/" + new Date().getFullYear());
        let { total, ticket } = response;

        const symbol = currencySymbol();

        response = {
          ...response,
          total: symbol + total,
          ticket: symbol + ticket,
        };

        setDados(response);
      } catch (err) {
        if (err.response) {
          popUp("error", "Oops...", err.response.data.message);
        }
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-md-4">
              <div className="card mini-stat bg-primary">
                <div className="card-body mini-stat-img">
                  <div className="mini-stat-icon">
                    <i className="mdi mdi-receipt float-right"></i>
                  </div>
                  <div className="text-white">
                    <h6 className="text-uppercase mb-3">
                      {" "}
                      {t("defaults:totalOrders")}:
                    </h6>
                    <h4 className="mb-4">

                      {loader ? (
                        <i className="fa  fa-spinner fa-pulse"></i>
                      ) : (
                        dados.orders
                      )}
                    </h4>
                    {/*   <span className="badge badge-success">{taxa_sucesso}%</span>
                    <span className="ml-2">Taxa de sucesso</span> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4">
              <div className="card mini-stat bg-primary">
                <div className="card-body mini-stat-img">
                  <div className="mini-stat-icon">
                    <i className="mdi mdi-currency-usd float-right"></i>
                  </div>
                  <div className="text-white">
                    <h6 className="text-uppercase mb-3">
                      {t("revenue")} - {year}
                    </h6>
                    <h4 className="mb-4">
                      {loader ? (
                        <i className="fa  fa-spinner fa-pulse"></i>
                      ) : (

                          process.env.REACT_APP_LANGUAGE !=='ptBr' ?
                              (<CurrencyFormat value={dados.total} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />)
                            : (<CurrencyFormat value={dados.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div>{value}</div>} />)


                      )}
                    </h4>
                    {/*      <span className="badge badge-success">
                      {receita_comparacao}
                    </span>
                    <span className="ml-2">Comparado ao ano anterior</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="card mini-stat bg-primary">
                <div className="card-body mini-stat-img">
                  <div className="mini-stat-icon">
                    <i className="mdi mdi-tag-text-outline float-right"></i>
                  </div>
                  <div className="text-white">
                    <h6 className="text-uppercase mb-3">
                      {t("averageTicket")}
                    </h6>
                    <h4 className="mb-4">
                      {loader ? (
                        <i className="fa  fa-spinner fa-pulse"></i>
                      ) : (
                          process.env.REACT_APP_LANGUAGE !=='ptBr' ?
                              (<CurrencyFormat value={dados.ticket} displayType={'text'} thousandSeparator={false} prefix={'$'} renderText={value => <div>{value}</div>} />)
                              : (<CurrencyFormat value={dados.ticket} displayType={'text'} thousandSeparator={false} prefix={'R$'} renderText={value => <div>{value}</div>} />)

                      )}
                    </h4>
                    {/*      <span className="badge badge-success"> {} % </span>{" "}
                    <span className="ml-2">Comparado ao ano anterior</span> */}
                  </div>
                </div>
              </div>
            </div>
            {/*    <div className="col-xl-6 col-md-6">
                <div className="card mini-stat bg-primary">
                  <div className="card-body mini-stat-img">
                    <div className="mini-stat-icon">
                      <i className="mdi mdi-star float-right"></i>
                    </div>
                    <div className="text-white">
                      <h6 className="text-uppercase mb-3">Avaliação média</h6>
                      <h4 className="mb-4">0</h4>
                      <span className="badge badge-danger"> {} </span>{" "}
                      <span className="ml-2">Ver dicas para melhorar</span>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>

        {/* <div className="container">
          <Col xs={12} className="m-auto">
            <>
              <OrdersChart typeData="awaiting_confirmation" />

              <OrdersChart typeData="pending" />

              <OrdersChart typeData="canceled" />
            </>
          </Col>

        </div> */}
      </div>
    </>
  );
}
