import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../Services/auth";
import Layout from "../Pages/Main/Includes/layout";

import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import ResetPassword from "../Pages/Auth/ResetPassword";

import Dashboard from "../Pages/Main/Dashboard";
import Pedidos from "../Pages/Main/Pedidos";
import Catalogo from "../Pages/Main/Catalogo";

import ConfigLoja from "../Pages/Main/ConfigLoja";
import Configuracoes from "../Pages/Main/Configuracoes";
import ConfigFrete from "../Pages/Main/ConfigFrete";
import SellerAssociations from "../Pages/Main/SellerAssociations";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <>
          <Component {...props} />
        </>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const base = "/:locale(pt|fr)?";

const Routes = () => (
  <Switch>
    <Route exact path={base} component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/reset-password" component={ResetPassword} />

    <Layout>
      <PrivateRoute path={"/dashboard"} component={Dashboard} />
      <PrivateRoute path={"/pedidos"} component={Pedidos} />
      <PrivateRoute path={"/catalogo"} component={Catalogo} />
      <PrivateRoute path="/associacoes" component={SellerAssociations} />
      <PrivateRoute path="/configloja" component={ConfigLoja} />
      <PrivateRoute path="/configuracoes" component={Configuracoes} />
      <PrivateRoute path="/configfrete" component={ConfigFrete} />
    </Layout>

    <Route path="*" component={() => <h1>Pagina não encontrada</h1>} />
  </Switch>
);

export default Routes;
