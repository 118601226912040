import Swal from "sweetalert2";

export default function popUp(icon, title, text) {
  Swal.fire({
    icon: icon,
    title: title,

    html: text,
  });

}
