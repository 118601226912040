import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../../../Services/api";
import "../../../Pages/Auth/Includes/Loader/Loader.css";

import popUp from "../../../Services/Utils/swalPopup";
import history from "../../../history";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../Contexts/hooks/useUserData";

export default function RegisterForm() {
	const [loader, setLoader] = useState();

	const [valorSelect, setValorSelect] = useState();

	const { loginService } = useUserData();

	const { t } = useTranslation("registerUser");

	const search = useLocation().search;
	const company = new URLSearchParams(search).get("company");

	const handleSignUp = async (fields) => {
		const { email, password } = fields;

		setLoader(true);
		const inputLogins = { email, password };

		try {
			await api.post("/register", fields);

			popUp("success", t("registerUser:success"));

			loginService(inputLogins, history);
		} catch (err) {
			popUp("error", "Oops...", err.response.data.message);
		} finally {
			setLoader(false);
		}
	};

	useEffect(() => {
		async function getCompany() {
			const { data: response } = await api.get(
				process.env.REACT_APP_PLATAZ_URL + "/api/company"
			);
			console.log("Resposta da requisição: ", response);
			setValorSelect(response.data);
		}
		getCompany();
	}, []);

	return (
		<Formik
			initialValues={{
				name: "",
				email: "",
				company_id: company !== null ? company : "",
				password: "",
				password_confirmation: "",
				url: "",
			}}
			validationSchema={Yup.object().shape({
				name: Yup.string()
					.required(t("form:errors.name"))
					.strict(false)
					.trim()
					.min(2, t("form:errors.nameLength")),
				email: Yup.string()
					.email(t("form:errors.email"))
					.required(t("form:errors.emailRequired")),
				password: Yup.string()
					.min(6, t("form:errors.minPassword"))
					.required(t("form:errors.password")),
				password_confirmation: Yup.string()
					.oneOf([Yup.ref("password"), null], t("form:errors.passwordEqual"))
					.required(t("form:errors.passwordConfirmation")),
				url: Yup.string()
					.matches(/^[A-Za-z-]+$/, t("form:errors.url"))
					.required(t("form:errors.urlRequired")),
				company_id: Yup.string().required("Por favor, selecione uma loja"),
				/* acceptTerms: Yup.bool()
                                .oneOf([true], 'Aceitar os termos e condições é obrigatório!') */
			})}
			onSubmit={(fields) => {
				handleSignUp(fields);
			}}
		>
			{({ errors, status, touched }) => (
				<Form>
					<div
						id="pre-loader"
						className="loader-container"
						style={{ display: loader ? "block" : "none" }}
					>
						<div className="loader">
							<i className="fa fa-2x fa-spinner fa-pulse"></i>
						</div>
					</div>

					<div className="pagesAuth form-group">
						<label htmlFor="name" className="text-dark">
							{t("defaults:name")}
						</label>
						<Field
							name="name"
							type="text"
							className={
								"pagesAuth form-control1" +
								(errors.name && touched.name ? " is-invalid" : "")
							}
						/>

						<ErrorMessage
							name="name"
							component="div"
							className="invalid-feedback"
							placeholder="Digite seu nome"
						/>
					</div>

					<div className="pagesAuth form-group">
						<label htmlFor="email" className="text-dark">
							{t("defaults:email")}
						</label>
						<Field
							name="email"
							type="text"
							className={
								"pagesAuth form-control1" +
								(errors.email && touched.email ? " is-invalid" : "")
							}
						/>
						<ErrorMessage
							name="email"
							component="div"
							className="invalid-feedback"
							placeholder="Digite um email"
						/>
					</div>

					{valorSelect && (
						<div className="pagesAuth form-group">
							<label htmlFor="select" className="text-dark">
								Loja
							</label>
							<Field
								name="company_id"
								as="select"
								className={
									" pagesAuth form-control1 " +
									(errors.company_id ? " is-invalid" : "")
								}
							>
								<option value="-1"> Selecione</option>
								{company
									? valorSelect.map((valor) =>
											company == valor.id ? (
												<option key={valor.id} value={valor.id}>
													{valor.name}
												</option>
											) : (
												""
											)
									  )
									: valorSelect.map((valor) => (
											<option key={valor.id} value={valor.id}>
												{valor.name}
											</option>
									  ))}
							</Field>
							<ErrorMessage
								name="company_id"
								component="div"
								className="invalid-feedback"
								placeholder="Selecione uma loja"
							/>
						</div>
					)}

					<div className="pagesAuth form-group">
						<label htmlFor="url" className="text-dark">
							{t("form:label.storeUrl")}
						</label>
						<Field
							name="url"
							type="text"
							className={
								"pagesAuth form-control1" +
								(errors.url && touched.url ? " is-invalid" : "")
							}
						/>

						<ErrorMessage
							name="url"
							component="div"
							className="invalid-feedback"
							placeholder="Digite a url da sua loja"
						/>

						<div className="text-dark mt-2">
							{t("exampleLabel")}
							<i>
								<b> {t("example")}</b>
							</i>
						</div>
					</div>

					<div className="pagesAuth form-row">
						<div className="pagesAuth form-group col">
							<label htmlFor="password" className="text-dark">
								{t("form:label.password")}
							</label>
							<Field
								name="password"
								type="password"
								className={
									"pagesAuth form-control1" +
									(errors.password && touched.password ? " is-invalid" : "")
								}
							/>
							<ErrorMessage
								name="password"
								component="div"
								className="invalid-feedback"
								placeholder="Digite uma senha"
							/>
						</div>
						<div className="pagesAuth form-group col">
							<label htmlFor="password_confirmation" className="text-dark">
								{t("form:label.confirmPassword")}
							</label>
							<Field
								name="password_confirmation"
								type="password"
								className={
									"pagesAuth form-control1" +
									(errors.password_confirmation && touched.password_confirmation
										? " is-invalid"
										: "")
								}
							/>
							<ErrorMessage
								name="password_confirmation"
								component="div"
								className="invalid-feedback"
								placeholder="Confirme a senha"
							/>
						</div>
					</div>
					{/* <div className="form-group form-check mt-4 text-right">
                            <Field type="checkbox" name="acceptTerms" id="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                            <label htmlFor="acceptTerms" className="form-check-label">Aceitar os termos e condições</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                        </div> */}
					<div className="pagesAuth form-group mt-4 text-right">
						{loader ? (
							""
						) : (
							<button type="submit" className="pagesAuth btn btn-primary mr-2">
								{t("buttonRegister")}
							</button>
						)}

						<button type="reset" className="pagesAuth btn btn-secondary">
							{t("clearButton")}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
}
