import i18n from "../../i18n";

var userLang = navigator.language || navigator.userLanguage;

const currencyFormat = (num) => {
  return Intl.NumberFormat(userLang, {
    style: "currency",
    currency: i18n.t("defaults:currency"),
    minimumFractionDigits: 2,
  }).format(num);
};

export default currencyFormat;
