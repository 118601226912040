import React from "react";
import "@mdi/font/css/materialdesignicons.min.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            © {new Date().getFullYear()} {t("defaults:enterprise")}{" "}
            {/*<span className="d-none d-sm-inline-block">*/}
            {/*  {" "}*/}
            {/*  - Desenvolvido com <i className="mdi mdi-heart text-danger"></i>*/}
            {/*</span>*/}
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
