const ptBr = {
  home: {
    welcome: "Bem vind@!",
    loginAdvise: "Faça o login para acessar o seu painel",
    password: "Senha",
    buttonLogin: "Acessar minha loja",
    forgotPassword: "Esqueceu sua senha?",
    createAccountPhrase: "Ainda não tem seus dados de acesso?",
    createAccountCallAction: " Solicite aqui",
    copyrightPhrase: "Desenvolvido com ",

    enterprise: "Cazco Digital",
  },

  registerUser: {
    exampleLabel: "Exemplo",
    example: "minha-super-loja",
    buttonRegister: "Registrar",
    clearButton: "Limpar",
    success: "Cadastro realizado com sucesso",
  },

  form: {
    label: {
      storeUrl: "Url da Loja",
      password: "Senha",
      confirmPassword: "Confirmar senha",
    },

    errors: {
      name: "Nome é obrigatório!",
      nameLength: "Nome precisar ter mais de 2 caracteres!",
      emailRequired: "Email é obrigatório!",
      email: "Email invalido!",

      password: "Senha é obrigatório!",
      minPassword: "A senha precisa ter mais de 6 caracteres!",
      passwordConfirmation: "Confirmar a senha é obrigatório!",
      passwordEqual: "As senhas devem corresponder!",
      url: "Digite a url corretamente",
      urlRequired: "Digite sua url",
    },
  },

  navbar: {
    welcome: "Bem vindo",
    logout: "Sair",
    dashboard: "Dashboard",
    orders: "Pedidos",
    catalog: "Catálogo",
    settings: "Configurações",
    help: "Ajuda",
  },
  dashboard: {
    revenue: "Receita",
    averageTicket: "Ticket Médio",
  },
  orders: {
    titlePage: "Pedidos pendentes",
    pendingOrdersPhrase:
      "Para sua comodidade, separamos os pedidos pendentes do restante:",
    buttonLoadAll: "Carregar todos",
    orders: "Pedidos",
    search: "Busca",
    generateOrdersReport: "Gerar Relatório de Pedidos",

    modalOrder: {
      customerData: "Dados do Cliente",
      billingFor: "Faturar para",
      totalShipping: "Total frete",

      totalOrder: "Total do pedido",
      subTotal: "Total produtos",
      shippingCompany: "Transportadora",
      noteNumber: "Nº da nota",
      noteKeyNumber: "Nº da chave da nota",
      shippingValue: "Valor de envio",
      shippingTimeDays: "Tempo de entrega em dias",
      trackingCode: "Código de rastreio",
      confirmOrder: "Confirmar pedido",
      unavailableOrder: "Indisponível",
      confirmSendOrder: "CONFIRMAR ENTREGA",
      tag: "Etiqueta",
      previsionToArrive: "Entrega prevista para o dia",
      concluded: "Concluído",
      canceled: "Cancelado",
      urlTracking: "URL do rastreio",
      insertNoteCode: "Insira o número da Nota Fiscal",
      insetKeyCode: "Insira a chave da Nota Fiscal",
      productCode: "Código do produto",
      quantity: "Quantidade",
      unitPrice: "Valor uni",
      product: "Produto",
    },
    table: {
      orderNumbers: "# do pedido",
      date: "Colocado em",
      total: "Total",
      send: "Envio",
      status: "Status",
      actions: "Ações",
      fields: {
        periodLabel: "Digite o período 00/00/0000",
        period: "Período",
        search: "Buscar",
        searchLabel: "Busque por número do pedido ou nome",
      },
    },
    noOrders: "sem pedidos",
  },
  dataTableDefault: {
    rowsPerPageText: "Linhas por página",
    rangeSeparatorText: "de",
    selectAllRowsItemText: "Todos",
  },
  catalog: {
    titlePage: "Meu catálogo de ofertas",
    downloadTableButton: "CARREGAR PRODUTOS PARA SUA LOJA",
    catalogPhrase:
      "Você pode buscar por ofertas específicas, ou também filtrar a exibição.",
    buttonLoadAll: "Carregar todos",
    addOffer: "ADICIONE PRODUTOS À SUA LOJA",
    addProduto: "Acesse o catálogo completo de produtos e venda em sua loja com apenas alguns cliques.",
    addProdutoMassa: "Faça upload de produtos que não estão em nosso catálogo para sua loja com o modelo de upload em massa.",
    listaProdutoMassa: "Aqui você pode fazer grandes modificações na lista de produtos que vende em sua loja.",
    helpPlanilha: "Ao baixar este modelo, você poderá modificar o preço, estoque e tempo de processamento.",
    downloadModelo: "BAIXE A LISTA DE PRODUTOS DA SUA LOJA",
    massiveUpload: "Depois de concluir seu modelo de modificações em massa, você pode carregá-lo em sua loja aqui.",
    uploadModelo: "CARREGAR LISTA DE PRODUTOS DA SUA LOJA",
    txtUp: "Aguarde para gerar arquivo",

    search: "Buscar",
    filter: {
      all: "Todos",
      active: "Ativo",
      paused: "Pausado",
    },

    noOffer: "Sem ofertas",
    tableCatalog: {
      image: "Imagem",

      branCode: "Código da Marca",
      sellerCode: "Código Seller",
      status: "Status",
      price: "Meu preço",
      stock: "Estoque",
      action: "Ações",

      inputPlaceHolder: "Digite nome do produto ou SKU",
    },
    tableProducts: {
      image: "Imagem",

      code: "sku",
      action: "Ação",
    },

    modalOffer: {
      sellerCode: "Código do lojista",
      availableStock: "Estoque",
      yourPrice: "Seu preço",
      processingDays: "Tempo de processamento",
      offerStatus: "Status da oferta",
    },

    titleModalProducts: "Selecione os produtos que quer vender",
  },

  configStore: {
    storeLogo: "Logo da loja ",
    change: "Alterar",

    form: {
      description: "Descrição",
      code: "CNPJ",
      street: "Rua",
      number: "Número",
      neighborhood: "Bairro",
      city: "Cidade",
      state: "Estado",
      country: "País",
      latitude: "Latitude",
      longitude: "Longitude",
      shippingRadius: "Raio de entrega",
      complement: "Complemento",
      openStore: "Horário de abertura da loja",
      closeStore: "Horário de fechamento da loja",
    },
  },

  config: {
    form: {
      selectYourBank: "Selecione o seu banco",
      bankCode: "Código do banco",
      agence: "Agência",
      agenceDigit: "Dígito da agência",
      account: "Conta",
      accountDigit: "Dígito da conta",
      accountType: "Tipo de conta",
      documentNumber: "CPF / CNPJ",
    },
  },

  components: {
    sidebar: {
      storeProfile: "Perfil da loja",
      financialConfig: "Configurações financeiras",
      trackingConfig: "Configurações de frete",
    },

    chartOrders: {
      form: {
        incorrectDate: "Digite a data completa",
        label: "Digite o período",
      },
      canceledLabelTitle: "Pedidos cancelados",
      pendingLabelTitle: "Pedidos pendentes",
      awaitingLabelTitle: "Pedidos aguardando confirmação",
    },
  },

  defaults: {
    name: "Nome",
    send: "Enviar",
    email: "Email",
    total: "Total",
    password: "Senha",
    confirmPassword: "Confirmar senha",
    phone: "Telefone",
    description: "Descrição",
    search: "Busca",
    buttonLoadAll: "Carregar todos",
    listLoaded: "Lista carregada!",
    currency: "BRL",
    save: "Salvar",
    status: "Status",
    loading: "Carregando",
    import: "Importar",
    filter: "Filtro",
    image: "Imagem",
    brand: "Marca",
    code: "Código",
    enterprise: "Cazco Digital",
    documentFormatPerson: "CPF",
    documentFormatCompany: "CNPJ",
    postCode: "CEP",
    delivery: "Entrega",
    currentPage: "Página atual",
    totalOrders: "Total de Pedidos",
    upload: "Upload",
    results: "Resultados",
  },
};

export default ptBr;
