import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export default function ResetPasswordForm() {
  const { t } = useTranslation("home");

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("form:erros.email"))
          .required(t("form:errors.email")),
      })}
      onSubmit={(fields) => {
        alert("Sucesso! \n\n" + JSON.stringify(fields, null, 4));
      }}
    >
      {({ errors, status, touched }) => (
        <Form>
          <div className="pagesAuth form-group">
            <label htmlFor="email" className="text-dark">
              Email
            </label>
            <Field
              name="email"
              type="text"
              className={
                "pagesAuth form-control1" +
                (errors.email && touched.email ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="email"
              component="div"
              className="invalid-feedback"
              placeholder="Digite um email"
            />
          </div>
          <div className="form-group mt-4 text-right">
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
