const es = {
  home: {
    welcome: "Bienvenid@!",
    loginAdvise: "Registrá tus datos para accesar a tu panel",
    password: "Contraseña",
    buttonLogin: "Ingresar a mi tienda",
    forgotPassword: "¿Olvidaste tu contraseña?",
    createAccountPhrase: "¿Todavía no tenés tus datos de ingreso?",
    createAccountCallAction: " Solicitalos aquí",
    copyrightPhrase: "Desarrollado por CaZco Digital y Operado por ",
    enterprise: "Servicios Zeta",
  },
  registerUser: {
    exampleLabel: "Ejemplo",
    example: "mi-super-tienda",
    buttonRegister: "Registrar",
    clearButton: "Limpiar",
    success: "Registro realizado con éxito"
  },
  form: {
    label: {
      storeUrl: "Nombre de tu tienda",
      password: "Contraseña",
      confirmPassword: "Confirmar contraseña",
    },
    errors: {
      name: "¡Se requiere el nombre!",
      nameLength: "¡El nombre debe de tener más de 2 caracteres!",
      emailRequired: "¡Se requiere de un correo electrónico!",
      email: "¡Correo electrónico inválido!",
      password: "¡Se requiere de una contraseña!",
      minPassword: "¡La contraseña debe de tener más de 6 caracteres!",
      passwordConfirmation: "¡Se requiere confirmación de la contraseña!",
      passwordEqual: "¡Las contraseñas deben de coincidir!",
      url: "Ingresar la url correctamente",
      urlRequired: "Ingresá tu url",
    },
  },
  navbar: {
    welcome: "Bienvenid@",
    logout: "Salir",
    dashboard: "Panel de Control",
    orders: "Pedidos",
    catalog: "Catálogo",
    settings: "Configuraciones",
    help: "Ayuda",
  },
  dashboard: {
    revenue: "Ingresos",
    averageTicket: "Ticket Promedio",
  },
  orders: {
    titlePage: "Pedidos pendientes",
    pendingOrdersPhrase:
      "Para tu comodidad, separamos los pedidos pendientes del resto:",
    buttonLoadAll: "Cargar todos",
    orders: "Pedidos",
    search: "¿Qué estás buscando?",
    generateOrdersReport: "Generar informe de pedido",
    modalOrder: {
      customerData: "Datos del Cliente",
      billingFor: "Facturar a",
      totalShipping: "Total de flete",
      totalOrder: "Total de pedido",
      subTotal: "Total productos",
      shippingCompany: "Compañía de Transporte",
      noteNumber: "Nº de la nota",
      noteKeyNumber: "Nº de clave de la nota",
      shippingValue: "Valor del envío",
      shippingTimeDays: "Tiempo de entrega en días",
      trackingCode: "Código de rastreo",
      confirmOrder: "Confirmar pedido",
      confirmSendOrder: "Confirmar la entrega de este pedido",
      tag: "Etiqueta",
      previsionToArrive: "Entrega programada para el día",
      concluded: "Concluído",
      canceled: "Cancelado",
      urlTracking: "URL de rastreo",
      insertNoteCode: "Ingresá el número de Factura",
      insetKeyCode: "Ingresá la clave de la Factura",
      productCode: "Código de producto",
      quantity: "Cantidad",
      unitPrice: "Valor uni",
      product: "Producto",
    },
    table: {
      orderNumbers: "# de pedido",
      date: "Colocado el",
      total: "Total",
      send: "Envío",
      status: "Estado",
      actions: "Acciones",

      fields: {
        periodLabel: "Ingrese el período 00/00/0000",
        period: "Período",
        search: "Buscar",
        searchLabel: "Buscar por número de pedido o nombre",
      },
    },
    noOrders: "sin pedidos",
  },
  dataTableDefault: {
    rowsPerPageText: "Líneas por página",
    rangeSeparatorText: "de",
    selectAllRowsItemText: "Todas",
  },
  catalog: {
    titlePage: "Mi catálogo de ofertas",
    downloadTableButton: "CARGÁ PRODUCTOS A TU TIENDA",
    catalogPhrase:
      "Podés buscar ofertas específicas o también filtrar la visualización.",
    buttonLoadAll: "Cargar Todas las Ofertas",
    addOffer: "AGREGÁ PRODUCTOS A TU TENDA",
    addProduto: "Accedé al catálogo completo de productos de Alluzy y vendelos en tu tienda en unos simples clicks.",
    addProdutoMassa: "Cargá productos que no están en nuestro catálogo a tu tienda con la plantilla de carga masiva.",
    listaProdutoMassa: "Aquí podrás hacer modificaciones masivas en el listado de productos que vendés en tu tienda.",
    helpPlanilha: "Descargando esta plantilla, podrás modificar precio, stock y tiempo de procesamiento.",
    downloadModelo: "DESCARGÁ LISTADO DE PRODUCTOS DE TU TIENDA",
    massiveUpload: "Una vez que completes tu plantilla de modificaciones masivas podrás subirla a tu tienda aquí.",
    uploadModelo: "UPLOAD DE LISTADO DE PRODUCTOS DE TU TIENDA",
    txtUp: "Espere a generar el archivo",
    search: "¿Qué estás buscando?",
    filter: {
      all: "Todos",
      active: "Activo",
      paused: "Pausado",
    },
    noOffer: "Sin ofertas",
    tableCatalog: {
      image: "Imagen",
      branCode: "Código Marca",
      sellerCode: "Código Seller",
      status: "Estado",
      price: "Mi Precio",
      stock: "Inventario",
      action: "Acciones",

      inputPlaceHolder: "Ingrese nombre o código de producto",
    },
    tableProducts: {
      image: "Imagen",
      code: "SKU",
      action: "Acción",
    },

    modalOffer: {
      sellerCode: "SKU de Vendedor",
      availableStock: "Inventario Disponible",
      yourPrice: "Precio de Venta",
      processingDays: "Tiempo de Procesamiento",
      offerStatus: "Estado",
    },

    titleModalProducts: "Seleccioná los productos que querés vender",
  },
  configStore: {
    storeLogo: "Logo de la tienda",
    change: "Cambiar",
    form: {
      description: "Descripción",
      code: "CUIT",
      street: "Calle",
      number: "Número",
      neighborhood: "Localidad",
      city: "Partido",
      state: "Provincia",
      country: "País",
      latitude: "Latitud",
      longitude: "Longitud",
      shippingRadius: "Radio de entrega",
      openStore: "Hora de apertura de la tienda",
      closeStore: "Hora de cierre de la tienda",
      complement:
        "Otros datos adicionales | Entre calles | Piso | Depto. | etc...",
    },
  },
  config: {
    form: {
      selectYourBank: "Seleccioná tu banco",
      bankCode: "Código del banco",
      agence: "Agencia",
      agenceDigit: "Dígito de la agencia",
      account: "Cuenta",
      accountDigit: "Dígito de la cuenta",
      accountType: "Tipo de cuenta",
      documentNumber: "CUIT",
    },
  },
  components: {
    sidebar: {
      storeProfile: "Perfil de la tienda",
      financialConfig: "Configuración financiera",
      trackingConfig: "Configuración de flete",
    },

    chartOrders: {
      form: {
        incorrectDate: "Ingrese la fecha completa",
        label: "Ingrese el período",
      },

      canceledLabelTitle: "Pedidos cancelados",
      pendingLabelTitle: "Pedidos pendientes",
      awaitingLabelTitle: "Pedidos pendientes de confirmación",
    },
  },
  defaults: {
    name: "Nombre",
    send: "Enviar",
    email: "Email",
    total: "Total",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    phone: "Teléfono",
    description: "Descripción",
    search: "¿Qué estás buscando?",
    buttonLoadAll: "Cargar todos",
    listLoaded: "¡Lista cargada!",
    currency: "ARS",
    save: "Guardar",
    status: "Estado",
    loading: "Cargando",
    import: "Importar",
    filter: "Filtro",
    image: "Imagen",
    brand: "Marca",
    code: "Código",
    enterprise: "Servicios Zeta",
    documentFormatPerson: "CUIT",
    documentFormatCompany: "CUIT",
    postCode: "Código Postal",
    delivery: "Entrega",
    currentPage: "Página actual",
    totalOrders: "Total de Pedidos",
    upload: "Upload",
    results: "Resultados",
  },
};

export default es;
