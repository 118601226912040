import React, { useEffect, useState } from "react";

import api from "../../Services/api";
import popUp from "../../Services/Utils/swalPopup";
import NavBarConfig from "./Includes/NavBarConfig";
import logoCazco from "../../assets/images/logo_cazco.svg";
import "./Configuracoes.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Pages/Auth/Includes/Loader/Loader.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useUserData } from "../../Contexts/hooks/useUserData";

function ConfigLoja() {
  const [inputDadata, setInputData] = useState();
  const [loader, setLoader] = useState(false);
  const [storeLogo, setStoreLogo] = useState(logoCazco);
  const { getDataUser, userData } = useUserData();

  const { companyId } = userData;

  const { handleSubmit, register } = useForm();
  const { t } = useTranslation("configStore");

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);

        const response = await api.get("/get");
        setInputData(response.data);
      } catch (err) {
        popUp("error", "Oops...", err.response.data.message);
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);

        const { data: response } = await api.get("/image");

        setStoreLogo(response.data);
      } catch (err) {
        popUp("error", "Oops...", err.response.data.message);
      } finally {
        setLoader(false);
      }
    })();
  }, []);

  const uploadStoreLogo = async (e) => {
    try {
      var formData = new FormData();

      formData.append("logo", e.target.files[0]);

      setStoreLogo(URL.createObjectURL(e.target.files[0]));

      await api.post("/image/upload-image", formData);
    } catch (err) {
      popUp("error", err.response.data.message);
    }
  };

  const getZippinStatus = async () => {
    try {
      const response = await api.get("/shipping/active", {
        params: { company_id: companyId },
      });

      const zippinStatus = response.data.find(
        (method) => method.title === "Zippin"
      );

      return zippinStatus ?? false;
    } catch (err) {
      popUp("error", "Oh No...", err.response.data.message);
    } finally {
    }
  };

  const createZippinOrigin = async () => {
    const isZippinActive = await getZippinStatus();

    if (isZippinActive) {
      try {
        await api.post("/zippin/create-origin");
      } catch (err) {
        setLoader(false);
      } finally {
      }
    }
  };

  const handleSellerUpdate = async (data) => {
    try {
      const response = await api.put("/profile", data);
      popUp("success", "Yeaaaah", response.data.message);

      await getDataUser();

    //   await createZippinOrigin();
      
    } catch (err) {
      if (err.response) {
        popUp("error", "Oops...", err.response.data.message);
        setLoader(false);
      }
    } finally {
    }
  };

  const handleUpdate = async (data) => {
    setLoader(true);

    await handleSellerUpdate(data);

    setLoader(false);
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          {/* <div className="row"> */}
          <div className="container-config">
            <NavBarConfig />

            <div className="card col-8">
              <div className="mt-3 p-0 w-100 text-center">
                <label className="mb-0 pb-0" htmlFor="customFileLang">
                  {t("storeLogo")}
                  <div className="my-4">
                    <div className="m-auto store-logo">
                      <img src={storeLogo} alt="" />
                    </div>
                  </div>
                  <div className="d-block p-0 mt-2">{t("change")}</div>
                </label>
                <div className="custom-file-img mr-2 pb-0 mt-2">
                  <input
                    type="file"
                    id="customFileLang"
                    lang="es"
                    onChange={(e) => uploadStoreLogo(e)}
                  />
                </div>
              </div>

              <div>
                <fieldset disabled={loader ? true : false}>
                  <form
                    className="form-config"
                    onSubmit={handleSubmit(handleUpdate)}
                  >
                    <label className="mt-3"> {t("defaults:name")}</label>
                    <input
                      ref={register}
                      name="name"
                      type="text"
                      defaultValue={inputDadata?.name}
                    />

                    <label className="mt-3">Email</label>
                    <input
                      ref={register}
                      name="email"
                      type="text"
                      defaultValue={inputDadata?.email}
                    />
                    <label className="mt-3">{t("defaults:phone")}</label>
                    <input
                      ref={register}
                      name="phone"
                      type="text"
                      defaultValue={inputDadata?.phone}
                    />

                    {/*    <label className="mt-3">Título da Loja</label>
                      <input ref={register}
                        name="shop_title"
                        type="text"
                        value={this.state.shop_title || ""}
                        onChange={(e) =>
                          this.setState({ shop_title: e.target.value })
                        }
                      /> */}
                    <label className="mt-3">{t("defaults:description")}</label>
                    <textarea
                      name="description"
                      type="textarea"
                      defaultValue={inputDadata?.description}
                      cols="30"
                      rows="10"
                    ></textarea>

                    <label className="mt-3">{t("form.code")}</label>
                    <input
                      ref={register}
                      name="tax_vat"
                      type="text"
                      defaultValue={inputDadata?.tax_vat}
                    />
                    <label className="mt-3">{t("form.street")}</label>
                    <input
                      ref={register}
                      name="address"
                      type="text"
                      defaultValue={inputDadata?.address}
                    />
                    <label className="mt-3">{t("form.number")}</label>
                    <input
                      ref={register}
                      name="number"
                      type="text"
                      defaultValue={inputDadata?.number}
                    />
                    <label className="mt-3">{t("form.neighborhood")}</label>
                    <input
                      ref={register}
                      name="neighborhood"
                      type="text"
                      defaultValue={inputDadata?.neighborhood}
                    />
                    <label className="mt-3">{t("defaults:postCode")}</label>
                    <input
                      ref={register}
                      name="postcode"
                      type="text"
                      defaultValue={inputDadata?.postcode}
                    />
                    <label className="mt-3">{t("form.city")}</label>
                    <input
                      ref={register}
                      name="city"
                      type="text"
                      defaultValue={inputDadata?.city}
                    />

                    <label className="mt-3">{t("form.complement")}</label>
                    <input
                      ref={register}
                      name="complement"
                      type="text"
                      defaultValue={inputDadata?.complement}
                    />
                    <label className="mt-3">{t("form.state")}</label>
                    <input
                      ref={register}
                      name="state"
                      type="text"
                      defaultValue={inputDadata?.state}
                    />
                    <label className="mt-3">{t("form.country")}</label>
                    <input
                      ref={register}
                      name="country"
                      type="text"
                      defaultValue={inputDadata?.country}
                    />

                    {/* 
                        <label className="mt-3">Politica de Retorno</label>
                        <input ref={register}
                          name="return_policy"
                          type="text"
                          value={this.state.return_policy || ""}
                          onChange={(e) =>
                            this.setState({ return_policy: e.target.value })
                          }
                        />

                      
                        <label className="mt-3">Shipping Policy</label>
                        <input ref={register}
                          name="shipping_policy"
                          type="text"
                          value={this.state.shipping_policy || ""}
                          onChange={(e) =>
                            this.setState({ shipping_policy: e.target.value })
                          }
                        />
                        <label className="mt-3">Privacy Policy</label>
                        <input ref={register}
                          name="privacy_policy"
                          type="text"
                          value={this.state.privacy_policy || ""}
                          onChange={(e) =>
                            this.setState({ privacy_policy: e.target.value })
                          }
                        /> */}

                    <label htmlFor="open" ref={register} className="mt-3">
                      {t("form.openStore")}:
                    </label>

                    <InputMask
                      defaultValue={inputDadata?.open}
                      ref={register}
                      mask="99:99"
                      name="open"
                      onChange={(e) =>
                        setInputData({ ...inputDadata, open: e.target.value })
                      }
                    />

                    <label htmlFor="close" ref={register} className="mt-3">
                      {t("form.closeStore")}:
                    </label>

                    <InputMask
                      defaultValue={inputDadata?.close}
                      mask="99:99"
                      ref={register}
                      name="close"
                      onChange={(e) =>
                        setInputData({
                          ...inputDadata,
                          close: e.target.value,
                        })
                      }
                    />

                    <label className="mt-3">{t("form.shippingRadius")}</label>
                    <input
                      ref={register}
                      name="service_radius"
                      type="number"
                      defaultValue={inputDadata?.service_radius}
                    />

                    <label className="mt-3">{t("form.latitude")}</label>
                    <input
                      ref={register}
                      name="latitude"
                      type="text"
                      defaultValue={inputDadata?.latitude}
                    />

                    <label className="mt-3">{t("form.longitude")}</label>
                    <input
                      ref={register}
                      name="longitude"
                      type="text"
                      defaultValue={inputDadata?.longitude}
                    />

                    <label className="mt-3">Facebook</label>
                    <input
                      ref={register}
                      name="facebook"
                      type="text"
                      defaultValue={inputDadata?.facebook}
                    />

                    <label className="mt-3">Instagram</label>
                    <input
                      ref={register}
                      name="instagram"
                      type="text"
                      defaultValue={inputDadata?.instagram}
                    />

                    <label className="mt-3"> {t("defaults:password")}</label>
                    <input ref={register} name="password" type="password" />
                    <label className="mt-3">
                      {" "}
                      {t("defaults:confirmPassword")}
                    </label>
                    <input
                      ref={register}
                      name="password_confirmation"
                      type="password"
                    />

                    <div className="form-group mt-4 text-center">
                      {loader ? (
                        <div>
                          <i className="fa fa-2x fa-spinner fa-pulse"></i>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mr-2"
                          style={{
                            backgroundColor: loader ? "#1f2431" : "",
                          }}
                        >
                          {t("defaults:save")}
                        </button>
                      )}
                    </div>
                  </form>
                </fieldset>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* end row */}
        </div>
        {/* end container-fluid */}
      </div>
    </>
  );
}

export default ConfigLoja;
