import React, { Component } from 'react'
import './formBox.css'
import Footer from '../../../Pages/Auth/Includes/Footer/footer'

class FormBox extends Component {
    render(props) {
        return (
            <div className="pagesAuth wrapper-page">
                <div className="pagesAuth card">
                    <div className="pagesAuth card-body rounded-lg bg-white">
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}

export default FormBox;