import React, { useCallback, useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../Services/api";
import Details from "../Main/Includes/Pedidos/Details";
import currencyFormat from "../../Services/Utils/currencyFormat";
import InputMask from "react-input-mask";
import popUp from "../../Services/Utils/swalPopup";

import { useTranslation } from "react-i18next";
import { Table, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

const firstPageUrl =
  process.env.REACT_APP_PLATAZ_URL + "/api/seller/order/list";

async function listOrders(url) {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (err) {
    return popUp("error", "Oh No...", err.response.data.message);
  }
}

async function getOrderDetails(id) {
  try {
    const response = await api.get(`${firstPageUrl}/${id}`);

    return response.data.data[0];
  } catch (err) {
    return popUp("error", "Oh No...", err.response.data.message);
  }
}

export default function Pedidos() {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [searchData, setSearchData] = useState();
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState(1);
  const [lastPage, setLastPage] = useState();

  const { register, handleSubmit, reset, errors } = useForm();

  const { t } = useTranslation("orders");

  const columns = [
    t("table.orderNumbers"),

    t("defaults:name"),

    t("table.date"),

    t("table.total"),

    t("table.send"),

    t("defaults:status"),

    t("table.actions"),
  ];

  const handleSearch = useCallback(async (page, params) => {
    const search = params?.search.length ? params.search : null;
    const created_at = params?.created_at?.length ? params.created_at : null;

    setLoading(true);
    try {
      const { data: response } = await api.get("/order/list", {
        params: {
          search,
          created_at,
          page: page ?? 0,
        },
      });

      const { meta, data, links } = response;

      const prevPageNumber = links.prev?.split("page=")[1];

      const nextPageNumber = links.next?.split("page=")[1];

      setPrevPage(prevPageNumber ? parseInt(prevPageNumber) : 1);
      setNextPage(nextPageNumber ? parseInt(nextPageNumber) : 1);

      setOrders(
        data.map((order) => ({
          ...order,
          grand_total: currencyFormat(order.grand_total),
        }))
      );

      setLastPage(meta.last_page);

      setTotalOrders(meta.total);

      setCurrentPage(parseInt(meta.current_page));
    } finally {
      setLoading(false);
    }
  }, []);

  async function handleData(values) {
    const { search, created_at } = values;

    if (!values) {
      setSearchData(null);
    }

    if (!search && !created_at) {
      return;
    }

    await handleSearch(0, values);

    setSearchData(values);
  }

  function clearFieldsSearch() {
    reset(["search", "created_at"]);
    if (!searchData) {
      return;
    }

    setSearchData(null);
    handleSearch();
  }

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateOrdersReport = async () => {
    try {
      const response = await api.get("/order/export");

      window.open(response.data.url);
    } catch (err) {
      return popUp(
        "error",
        "Oh Não... Não foi possivel processar sua solicitação",
        err.response.data.message
      );
    }
    console.log("gerando relatorio");
  };

  const ordersReload = async () => {
    await handleSearch(currentPage, searchData);
  };

  const closeModal = () => {
    setDetails("");
  };

  const changeOrderState = (orderState) => {
    const currentOrder = details;
    const newOrder = { ...currentOrder, status: orderState };

    setDetails(newOrder);
  };

  const changeOrderNoteStatus = (noteStatus) => {
    const currentOrder = details;
    const newOrder = { ...currentOrder, note_status: noteStatus };

    setDetails(newOrder);
  };

  const loadDataModal = async (productId) => {
    let detailsModal = await getOrderDetails(productId);

    /*   const cpfMask = new StringMask("000.000.000-00");
    const cnpjMask = new StringMask("99.999.999/9999-99");

    detailsModal = {
      ...detailsModal,
      customer_vat_number:
        detailsModal.customer_vat_number.length > 11
          ? cnpjMask.apply(detailsModal.customer_vat_number)
          : cpfMask.apply(detailsModal.customer_vat_number),
    }; */

    detailsModal = {
      ...detailsModal,
      items: detailsModal.items.map((item) => ({
        ...item,
        price: currencyFormat(parseFloat(item.price)),
        priceByQty: parseFloat(item.price) * item.qty,
      })),
    };

    const total_products = detailsModal.items.reduce((a, b) => {
      return a + b.priceByQty;
    }, 0);

    detailsModal.sub_total = currencyFormat(total_products);

    setDetails(detailsModal);
  };

  return (
    <>
      <div className="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="mt-0 header-title">{t("titlePage")}</h4>
                  <p className="text-white-50 m-b-30">
                    {t("pendingOrdersPhrase")}
                  </p>

                  <div className="mb-4">
                    <button
                      className="btn btn-success btn-lg waves-effect waves-light"
                      onClick={() => generateOrdersReport()}
                    >
                      {t("generateOrdersReport")}
                    </button>
                  </div>

                  <fieldset disabled={loading}>
                    <form onSubmit={handleSubmit(handleData)}>
                      <Row className="mb-4  justify-content-center align-items-center">
                        <Col xs={4}>
                          <label htmlFor="created_at">
                            {t("table.fields.period")}
                          </label>
                          <InputMask
                            onChange={(e) =>
                              !e.target.value &&
                              setSearchData({
                                ...searchData,
                                created_at: null,
                              })
                            }
                            mask="99/99/9999"
                            type="search"
                            name="created_at"
                            placeholder={t("table.fields.periodLabel")}
                            ref={register({
                              minLength: 10,
                            })}
                          />

                          {errors.created_at?.type === "minLength" && (
                            <div className="mt-2"> Digite a data completa </div>
                          )}
                        </Col>

                        <Col xs={8}>
                          <Row
                            noGutters
                            className=" justify-content-center align-items-center"
                          >
                            <Col xs={12}>
                              <label htmlFor="search">
                                {t("defaults:search")}
                              </label>
                            </Col>
                            <Col xs={9}>
                              <input
                                type="search"
                                className="  bg-primary px-3 text-white rounded-left"
                                placeholder={t("table.fields.searchLabel")}
                                name="search"
                                onChange={(e) =>
                                  !e.target.value &&
                                  setSearchData({
                                    ...searchData,
                                    search: null,
                                  })
                                }
                                ref={register}
                              />
                            </Col>
                            <Col xs={3}>
                              <span className="mx-2">
                                <Button className="m-0" type="submit">
                                  <i className="fas fa-search"></i>
                                </Button>
                              </span>

                              <Button
                                variant="secondary"
                                className="m-0"
                                type="button"
                                onClick={() => clearFieldsSearch()}
                              >
                                <i className="far fa-trash-alt"></i>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </fieldset>

                  {loading ? (
                    <div className="text-center mt-5">
                      <i className="fa fa-2x fa-spinner fa-pulse"></i>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      {orders.length === 0 ? (
                        <div className="h4 mt-5 text-center w-100">
                          {t("noOrders")}
                        </div>
                      ) : (
                        <>
                          <div className="text-right h5">
                            <span className="font-weight-light text-white-50">
                              {" "}
                              {t("defaults:totalOrders")}:
                            </span>{" "}
                            <strong> {totalOrders}</strong>
                          </div>
                          <Table striped hover>
                            <thead>
                              <tr>
                                {columns.map((column) => (
                                  <th key={column}>{column}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {orders.map((order) => (
                                <tr key={order.id}>
                                  <td>{order.id}</td>
                                  <td>
                                    {order.customer_first_name}{" "}
                                    {order.customer_last_name}
                                  </td>
                                  <td> {order.created_at}</td>
                                  <td>{order.grand_total}</td>
                                  <td>{order.shipping_company}</td>
                                  <td>
                                    <div
                                      className={`${order.status} text-center p-1 rounded`}
                                    >
                                      {order.status_label}
                                    </div>
                                  </td>
                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() => loadDataModal(order.id)}
                                    >
                                      Ver
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="btns-nav d-flex align-items-center justify-content-end">
                            <Button
                              variant="secondary"
                              className="bg-none rounded-circle mr-1 d-flex align-items-center justify-content-center"
                              onClick={() => handleSearch(1, searchData)}
                              disabled={currentPage === 1}
                            >
                              <i className="fas fa-angle-double-left h5 m-0"></i>
                            </Button>

                            <Button
                              onClick={() => handleSearch(prevPage, searchData)}
                              className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
                              disabled={currentPage <= prevPage}
                            >
                              <i className="fas fa-angle-left h5 m-0"></i>
                            </Button>

                            <Button
                              onClick={() => handleSearch(nextPage, searchData)}
                              className="rounded-circle d-flex  mr-1  align-items-center justify-content-center "
                              disabled={currentPage >= nextPage}
                            >
                              <i className="fas fa-angle-right h5 m-0"></i>
                            </Button>

                            <Button
                              variant="secondary"
                              className="rounded-circle mr-1 d-flex align-items-center justify-content-center"
                              disabled={currentPage === lastPage}
                              onClick={() => handleSearch(lastPage, searchData)}
                            >
                              <i className="fas fa-angle-double-right h5 m-0"></i>
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*    <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0 header-title">Histórico de pedidos</h4>
                <p className="text-white-50 m-b-30">
                  Após completos/cancelados os pedidos serão exibidos nesta
                  lista:
                </p>
              </div>
            </div>
          </div>
        </div>
   
      </div> */}

      {details && (
        <Details
          onHide={closeModal}
          changeOrderState={changeOrderState}
          loadDataModal={loadDataModal}
          order={details}
          listOrders={listOrders}
          firstPageUrl={firstPageUrl}
          ordersReload={ordersReload}
          changeOrderNoteStatus={changeOrderNoteStatus}
        />
      )}
    </>
  );
}
