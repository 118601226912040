import React from "react";
import Header from "../Includes/Header/header";

/* import '../../../../src/assets/css/foxia.css' */
import "../Includes/layout.css";

export default function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
    </>
  );
}
