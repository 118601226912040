import React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../src/assets/css/icons.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "../../../../src/assets/css/style.css";

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}
