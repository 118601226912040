import React, { Component } from 'react'
import '@mdi/font/css/materialdesignicons.min.css'
import { Link } from 'react-router-dom'

class NavbarLogin extends Component {
    render() {
        return (
            <div className="home-btn d-none d-sm-block">
                <Link to='/' className="text-white">
                    <i className="mdi mdi-home h1" style={{fontSize: '30px', margin: '10px'}}></i>
                </Link>
            </div>
        )
    }
}

export default NavbarLogin;