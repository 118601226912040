import React, { createContext, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import history from "../history";

const LocaleContext = createContext();

function LocaleProvider({ children }) {
  const { i18n } = useTranslation();

  const localeQuery = new URLSearchParams(history.location.search);

  const [locale, setlocale] = useState(
    localStorage.getItem("lang") || process.env.REACT_APP_LANGUAGE
  );

  const handleLocale = (locale) => {
    localStorage.setItem("lang", locale);
    setlocale(locale);
  };

  useEffect(() => {
    const query = localeQuery.get("lang");

    if (query) {
      handleLocale(query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  return (
    <LocaleContext.Provider value={{ handleLocale, locale }}>
      {children}
    </LocaleContext.Provider>
  );
}

export const useLocale = () => useContext(LocaleContext);

export default LocaleProvider;
