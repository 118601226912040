import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation("home");

  return (
    <div className="mt-4 mb-0 text-center text-white-50">
      <p>
        {t("createAccountPhrase")}
        <Link to={"/register"} className="font-600 text-white">
          {" "}
          {t("createAccountCallAction")}
        </Link>{" "}
      </p>
      <p>
        © 2020 {t("copyrightPhrase")}{" "}
        <i className="mdi mdi-heart text-danger"></i>
        <a
          href={"https://cazco.digital"}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-1"
          style={{ color: "#F27326" }}
        >
          {t("enterprise")}
        </a>
      </p>
    </div>
  );
}

export default Footer;
